import { Config } from "../config/Config";
import { ERRORS } from "../constants/constants";

const serviceUrlToAPIUrl = (serviceUrl) => {
    let apiUrl = "";
    if (serviceUrl.includes(":4000")) {
        apiUrl = serviceUrl.replace(":4000", ":4001");
    } else if (serviceUrl.includes(":5000")) {
        apiUrl = serviceUrl.replace(":5000", ":5001");
    } else {
        apiUrl = serviceUrl;
    }

    return apiUrl;
};

const checkStatusCode = (res) => {
    return !!res.ok;
};

const apiUrl = serviceUrlToAPIUrl(Config.serviceUrl);

const apApiService = {
    /** PUBLIC: public routes (no authentication needed) **/
    getPublicServiceInfo: async (serviceId) => {
        const url = `${apiUrl}/public/services/${serviceId}`;
        const response = {
            publicServiceInfo: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "GET",
            });
            try {
                const responseJson = await apiResponse.json();
                if (checkStatusCode(apiResponse)) {
                    response.publicServiceInfo = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    getPublicProductionInfo: async (serviceId, productionId) => {
        const url = `${apiUrl}/public/productions/${serviceId}/${productionId}`;
        const response = {
            publicProductionInfo: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "GET",
            });
            try {
                const responseJson = await apiResponse.json();
                if (checkStatusCode(apiResponse)) {
                    response.publicProductionInfo = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    getPublicCompositionInfo: async (serviceId, compositionId) => {
        const url = `${apiUrl}/public/compositions/${serviceId}/${compositionId}`;
        const response = {
            publicCompositionInfo: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "GET",
            });
            try {
                const responseJson = await apiResponse.json();
                if (checkStatusCode(apiResponse)) {
                    response.publicCompositionInfo = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    checkProductionToken: async (serviceId, productionId, token) => {
        const url = `${apiUrl}/public/productions/${serviceId}/${productionId}/token`;
        const response = {
            valid: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({ token: token }),
            });
            try {
                const responseJson = await apiResponse.json();
                if (checkStatusCode(apiResponse)) {
                    response.valid = responseJson.valid;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    checkProductionAccess: async (serviceId, productionId, token, fields) => {
        let fieldsParam = "";
        if (fields && fields.length) {
            fieldsParam += "?fields=";
            fieldsParam += fields.join("%2C");
        }

        const url = `${apiUrl}/public/productions/${serviceId}/${productionId}/access${fieldsParam}`;
        const response = {
            result: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({ token: token }),
            });
            try {
                const responseJson = await apiResponse.json();
                if (checkStatusCode(apiResponse)) {
                    response.result = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    checkCompositionToken: async (serviceId, compositionId, token) => {
        const url = `${apiUrl}/public/compositions/${serviceId}/${compositionId}/token`;
        const response = {
            valid: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({ token: token }),
            });
            try {
                const responseJson = await apiResponse.json();
                if (checkStatusCode(apiResponse)) {
                    response.valid = responseJson.valid;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },
};

export default apApiService;
