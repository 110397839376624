import { readFromLocalStorage, storeToLocalStorage } from "../../base/utils/localStorage";

export const getSavedClientInfo = () => {
    try {
        let savedClientInfo = readFromLocalStorage("ac-client-info");
        if (savedClientInfo) return JSON.parse(savedClientInfo);
    } catch (e) {}
    return null;
};

export const setSavedClientInfo = (savedClientInfo) => {
    savedClientInfo = JSON.stringify(savedClientInfo);
    storeToLocalStorage("ac-client-info", savedClientInfo);
};
