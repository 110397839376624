export const APP_STATE = {
    LOADING: "LOADING",
    SIGN_IN: "SIGN_IN",
    WAITING: "WAITING",
    NOT_AVAILABLE: "NOT_AVAILABLE",
    AUTHENTICATE: "AUTHENTICATE",
    AUTHENTICATED: "AUTHENTICATED",
    ACCESS_DENIED: "ACCESS_DENIED",
    ARCHIVED: "ARCHIVED",
    INIT_FAILURE: "INIT_FAILURE",
};

export const BROWSER_MIN_VERSIONS = {
    Chrome: 66,
    Firefox: 63,
    Opera: 53,
    Edge: 80,
    Safari: 11,
    "Mobile Safari": 11,
    "Samsung Browser": 9.2,
};

export const RESPONSIVE_MODES = {
    SMALL: 0,
    FULL: 780,
};

export const COMMUNICATOR_MODES = {
    DEFAULT: "DEFAULT",
    PREVIEW: "PREVIEW",
};

export const PLAYER_MODES = {
    LIVE: "LIVE",
    VOD: "VOD",
};

export const URL_PARAMS = {
    ID: "id",
    POPOUT: "popout",
    PREVIEW: "preview",
    AUTH: "auth",
    USER_NAME: "uName",
};

export const ERRORS = {
    SERVER_UNREACHABLE_ERROR: {
        internalError: true,
        errorNo: 0,
        message: "could not reach adiaCast server",
    },
    PARSING_ERROR: {
        internalError: true,
        errorNo: 1,
        message: "could not parse response",
    },
    DEFAULT_ERROR: {
        internalError: true,
        errorNo: 2,
        message: "something went wrong",
    },
    INVALID_SERVICE: {
        internalError: true,
        errorNo: 3,
        message: "service not found",
    },
    INVALID_ID: {
        internalError: true,
        errorNo: 4,
        message: "production/composition with this id not found",
    },
    ON_DEMAND_PRODUCTION: {
        internalError: true,
        errorNo: 5,
        message: "production is a not yet archived on demand production",
    },
    INVALID_TOKEN_ERROR: {
        errorNo: 6,
        message: "invalid token",
    },
};
