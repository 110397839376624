export const italian = {
    language: "Lingua",

    de: "Tedesco",
    en: "Inglese",
    fr: "Francese",
    it: "Italiano",

    // Player Panel
    offline: "OFFLINE",
    online: "ONLINE",
    posterTextPre: "La trasmissione inizierà a breve.",
    posterTextPost: "La trasmissione è terminata. Grazie per aver guardato!",
    overlayTextPlayerError:
        "Il tuo browser o dispositivo non supporta la riproduzione di questa trasmissione.\nSi prega di utilizzare un altro browser diverso o un dispositivo differente.",

    // Chat Panel
    chatHeader: "Stream Chat",
    chatTabChat: "Chat",
    chatTabPolls: "Sondaggi",
    chatTabDocuments: "Documenti",
    chatHide: "Nascondi la chat",
    chatPopout: "Pop-Out Chat",
    chatDisabledText: "La chat è disattivata.",
    chatPopoutText: "La chat è in modalità Pop-Out.",
    chatPopoutShow: "Visualizza Pop-Out",
    chatPopoutClose: "Chiudi Pop-Out",

    // Description Panel
    moderator: "Moderatore",
    direction: "Regia",
    company: "Azienda",
    descriptionNotAvailable: "Nessuna descrizione disponibile",

    // Landing Page
    browserNotSupportedText: "Il tuo browser non è supportato. Si prega di utilizzare un altro browser.",
    browserVersionNotSupportedText:
        "Questa versione del tuo browser non è più supportata. Per favore aggiorna il tuo browser o usa un altro browser.",

    signInPanelSignIn: "Accedi",
    signInPanelName: "Nome",
    termsOfUse: "Condizioni d'uso",
    acceptTermsOfUse: "Accetto le condizioni d'uso.",
    legalNotice: "Informazioni legali",

    infoPanelArchiveText:
        "La trasmissione è stata archiviata. Per guardare la trasmissione archiviata clicca sul bottone sottostante.",
    infoPanelArchiveTextLiveOnly: "Questa trasmissione è terminata e la registrazione non verrà pubblicata.",
    infoPanelArchiveNext: "Guardare la trasmissione archiviata",
    infoPanelWaitingText:
        "Questa trasmissione non è ancora pronta. Si prega di attendere che sia resa disponibile dalla regia.",
    infoPanelScheduleText1: "Questa trasmissione sarà disponibile tra:",
    infoPanelScheduleText2: "Questa trasmissione non è più disponibile.",
    tokenInputPanelText:
        "Questa trasmissione è protetta da un codice d'accesso. Inserisci il codice d'accesso per accedervi.",
    tokenInputPanelToken: "Codice d'accesso",
    tokenInputPanelAuthenticate: "Invia",
    tokenInputPanelTokenError: "Il codice d'accesso inserito non è corretto!",
    accessDeniedText: "Non avete accesso a questa trasmissione.",
    errorPanelDefaultText: "Ops! Qualcosa è andato storto.",
    errorPanelInvalidIdText: "Questa trasmissione non esiste.",
    errorPanelOnDemandProductionText: "Questa trasmissione deve ancora essere pubblicata.",

    errorPanelLiveOnlyProduction: "Questa trasmissione è terminata e la registrazione non verrà pubblicata.",
};
