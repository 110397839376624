import favicon from "../assets/favicons/favicon_adiacom.ico";

export const Config = {
    serviceUrl: "https://ac-app01.adia.tv",
    serviceIdFromUrl: true,

    keldanoDomain: "ac-keldano01.adia.tv",

    favicon,
};
