import { getTranslatedString, languages } from "../../base/i18n/translations";

export const transformProductionPayload = (production) => {
    production = { ...production };

    if (
        production.hasOwnProperty("communicatorCustomization") &&
        Array.isArray(production.communicatorCustomization.signInInputs)
    ) {
        production.communicatorCustomization.signInInputs = production.communicatorCustomization.signInInputs.map(
            (item) => {
                // try to parse validator as RegExp
                if (item.validator) {
                    try {
                        item.validator = new RegExp(item.validator);
                    } catch (e) {
                        item.validator = null;
                    }
                }
                // try to parse language overrides
                if (item.languageOverrides) {
                    try {
                        item.languageOverrides = JSON.parse(item.languageOverrides);
                    } catch (e) {
                        item.languageOverrides = null;
                    }
                }
                // set default translations for "name" input if no overrides are defined
                if (!item.languageOverrides && item.name === "name") {
                    item.languageOverrides = Object.values(languages).reduce(
                        (acc, curr) => ({
                            ...acc,
                            [curr]: getTranslatedString(curr, "signInPanelName"),
                        }),
                        {}
                    );
                }
                return item;
            }
        );
    } else {
        if (!production.communicatorCustomization) production.communicatorCustomization = {};

        production.communicatorCustomization.signInInputs = [
            {
                name: "name",
                show: true,
                required: true,
                validator: "",
                default: "",
                languageOverrides: Object.values(languages).reduce(
                    (acc, curr) => ({
                        ...acc,
                        [curr]: getTranslatedString(curr, "signInPanelName"),
                    }),
                    {}
                ),
            },
        ];
    }

    return production;
};
