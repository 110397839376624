import * as types from "./actionTypes";
import { RESPONSIVE_MODES } from "../../../constants/constants";

export const initialState = {
    responsiveMode: document.body.clientWidth < RESPONSIVE_MODES.FULL ? RESPONSIVE_MODES.SMALL : RESPONSIVE_MODES.FULL,
};

export default function global(state = initialState, action) {
    switch (action.type) {
        case types.RESPONSIVE_MODE_CHANGE:
            return {
                ...state,
                responsiveMode: action.responsiveMode,
            };

        default:
            return state;
    }
}
