import * as types from "./actionTypes";

export const initialState = {
    displayStream: false,
    chatOpen: false,
    unreadChatMessages: 0,
    playerMode: "theatre",
};

export default function livePlayer(state = initialState, action) {
    switch (action.type) {
        case types.TOGGLE_CHAT:
            return {
                ...state,
                chatOpen: !state.chatOpen,
                unreadChatMessages: !state.chatOpen ? 0 : state.unreadChatMessages,
            };

        case types.SHOW_STREAM:
            return {
                ...state,
                displayStream: true,
            };

        case types.HIDE_STREAM:
            return {
                ...state,
                displayStream: false,
            };

        case types.TOGGLE_PLAYER_MODE:
            return {
                ...state,
                playerMode: state.playerMode === "default" ? "theatre" : "default",
            };

        case types.NEW_CHAT_MESSAGES:
            return {
                ...state,
                unreadChatMessages: state.unreadChatMessages + action.count,
            };

        default: {
            return state;
        }
    }
}
