import merge from "lodash/merge";
import {
    PLAYER_DESTROY,
    PLAYER_HIDE,
    PLAYER_INIT,
    PLAYER_LOAD,
    PLAYER_PAUSE,
    PLAYER_PLAY,
    PLAYER_REFRESH,
    PLAYER_RESET,
    PLAYER_SEEK,
    PLAYER_SET_QUALITY_LEVEL,
    PLAYER_SET_SOURCE,
    PLAYER_SET_VOLUME,
    PLAYER_SHOW,
    PLAYER_STOP,
    PLAYER_TOGGLE_FULLSCREEN,
} from "../libs/@adiacast/player/src/view/redux/actionTypes";
import {
    playerAvailableQualities,
    playerFullscreenChange,
    playerHide,
    playerInit,
    playerInitialized,
    playerLoaded,
    playerPlaybackNotAllowed,
    playerPlayStateChange,
    playerQualityLevelChange,
    playerResolutionChange,
    playerShow,
    playerStop,
    playerVolumeChange,
} from "../libs/@adiacast/player/src/view/redux/actions";
import { ClientState } from "@adiacast/connector";
import { connectorSetClientStateAdd, connectorSetClientStateRemove } from "../features/connector/actions";
import { hideStream, showStream } from "../features/liveCommunicator/redux/actions";
import { log, logLevel } from "../features/base/utils/logger";
import { PLAYER_MODES } from "../constants/constants";

export const createPlayerMiddleware = () => {
    let loadPlayerPromise;
    let Player, ErrorCodes, MultiPubSub;
    let player = null;
    let playerElement = null;
    let videoElementContainer = null;
    let playerEventHandler = null;
    let playerPubSub = null;

    let fullscreenChangeHandlerRef;

    const loadPlayerLibrary = () => {
        if (loadPlayerPromise) return loadPlayerPromise;
        return (loadPlayerPromise = new Promise((resolve, reject) => {
            if (Player) return resolve();

            Promise.all([
                import("@adiacast/player"),
                //import('../libs/@adiacast/player/src/player'),
                import("../libs/pubsub/multi-pubsub"),
            ])
                .then((importRes) => {
                    Player = importRes[0].default;
                    ErrorCodes = importRes[0].ErrorCodes;
                    MultiPubSub = importRes[1].default;

                    resolve();
                })
                .catch((err) => reject(err));
        }));
    };

    const fullscreenChangeHandler = (store) => () => {
        if (document.fullscreenElement) {
            store.dispatch(playerFullscreenChange(true));
        } else {
            if (fullscreenChangeHandlerRef) {
                document.removeEventListener("fullscreenchange", fullscreenChangeHandlerRef);
            }

            store.dispatch(playerFullscreenChange(false));
        }
    };

    const livePlayerEventHandler = (store) => ({
        onError(msg, e) {
            log.debug(msg, e);
            switch (e.type) {
                case ErrorCodes.ID3_TAGS_MISSING:
                    // TODO: stream will play with fallback -> do something else instead?
                    /*store.dispatch(playerError(e));
                    store.dispatch(playerStop());*/
                    break;
                default:
            }
        },
        onInit() {
            store.dispatch(playerInitialized(player.type, Player.uaParserResult));
        },
        onTimeCodeChange(msg, e) {
            log.debug(msg, e);
            if (!e) return;

            const streamIsShown = store.getState().livePlayer.displayStream;
            if (e.uptime > 0) {
                if (!streamIsShown) {
                    store.dispatch(playerShow());
                    store.dispatch(showStream());
                    store.dispatch(connectorSetClientStateAdd(ClientState.VIDEO));
                }
            } else {
                if (streamIsShown) {
                    store.dispatch(hideStream());
                    store.dispatch(playerHide());

                    // check if stream is still on air; if so another stream has been started in the meantime
                    const isOnAir = store.getState().connector.onAir;
                    if (!isOnAir) {
                        store.dispatch(playerStop());
                    }
                    store.dispatch(connectorSetClientStateRemove(ClientState.VIDEO));
                }
            }
        },
        onPlayStateChange(msg, e) {
            log.debug(msg, e);
            store.dispatch(playerPlayStateChange(e.playing));
        },
        onPlaybackNotAllowed(msg, e) {
            log.debug(msg, e);
            store.dispatch(playerPlaybackNotAllowed());
        },
        onVolumeRequested(msg, e) {
            if (player.isHidden) {
                store.dispatch(playerVolumeChange(e.requestedVolume));
            }
        },
        onVolumeChange(msg, e) {
            store.dispatch(playerVolumeChange(e.volume));
        },
        onQualitiesAvailable(msg, e) {
            store.dispatch(playerAvailableQualities(e.qualities));
        },
        onQualityLevelSet(msg, e) {
            store.dispatch(playerQualityLevelChange(e.level));
        },
        onResolutionChange(msg, e) {
            store.dispatch(playerResolutionChange(e.resolution));
        },
    });

    const vodPlayerEventHandler = (store, notificationHandlers) => ({
        onError(msg, e) {
            log.error(msg, e);
            switch (e.type) {
                case ErrorCodes.SWF_FAILURE:
                    break;
                case ErrorCodes.SWF_MISSING:
                    break;
                default:
            }
        },
        onInit() {
            store.dispatch(playerInitialized(player.type, Player.uaParserResult));
        },
        onDuration(msg, e) {
            notificationHandlers.onDuration(e);
        },
        onTimeCodeChange(msg, e) {
            notificationHandlers.onTimeCodeChange(e);
        },
        onPlayStateChange(msg, e) {
            if (typeof notificationHandlers.onPlayStateChange === "function") {
                notificationHandlers.onPlayStateChange(e.playing);
            }

            store.dispatch(playerPlayStateChange(e.playing));
        },
        onPlaybackNotAllowed() {
            store.dispatch(playerPlaybackNotAllowed());
        },
        onVolumeChange(msg, e) {
            store.dispatch(playerVolumeChange(e.volume));
        },
    });

    return (store) => (next) => (action) => {
        switch (action.type) {
            case PLAYER_LOAD:
                playerElement = action.playerElement;
                videoElementContainer = action.videoElementContainer;

                loadPlayerLibrary()
                    .then(() => {
                        store.dispatch(playerLoaded(Player.isFullscreenSupported, Player.isVolumeSupported));
                    })
                    .catch((err) => {
                        log.error(err);
                    });

                return next(action);

            case PLAYER_INIT:
                loadPlayerLibrary()
                    .then(() => {
                        if (player) {
                            player.removeAllCallbacks();
                            player.destroy();
                            player = null;
                            playerPubSub = null;
                        }

                        const notificationHandlers = action.notificationHandlers || {};
                        const isLivePlayer = store.getState().landingPage.playerMode === PLAYER_MODES.LIVE;

                        if (isLivePlayer) {
                            playerPubSub = MultiPubSub.getPubSub("live_player_" + new Date().getTime());

                            let options = {
                                type: "auto",
                                mode: "live",
                                logLevel: logLevel,
                                useLiveLowLatency: false,
                                PS: playerPubSub,
                            };
                            if (action.options) {
                                options = merge(options, action.options);
                            }

                            player = new Player(options);

                            playerEventHandler = livePlayerEventHandler(store);
                            player.addOnError(playerEventHandler.onError);
                            player.addOnInit(playerEventHandler.onInit);
                            player.addOnTimeCodeChange(playerEventHandler.onTimeCodeChange);
                            player.addOnPlayStateChange(playerEventHandler.onPlayStateChange);
                            player.addOnVolumeRequested(playerEventHandler.onVolumeRequested);
                            player.addOnVolumeChange(playerEventHandler.onVolumeChange);
                            player.addOnPlaybackNotAllowed(playerEventHandler.onPlaybackNotAllowed);
                            player.addOnQualitiesAvailable(playerEventHandler.onQualitiesAvailable);
                            player.addOnQualityLevelSet(playerEventHandler.onQualityLevelSet);
                            player.addOnResolutionChange(playerEventHandler.onResolutionChange);

                            player.setVolume(store.getState().player.volume);
                            player.hide();
                            player.init(videoElementContainer);
                        } else {
                            playerPubSub = MultiPubSub.getPubSub("vod_player_" + new Date().getTime());

                            /*playerPubSub.subscribe('adiacast', (msg, e) => {
                                console.log(msg, e);
                            });*/

                            let options = {
                                type: "auto",
                                mode: "ondemand",
                                PS: playerPubSub,
                                //nativePlayerPossible:true
                            };
                            if (action.options) {
                                options = merge(options, action.options);
                            }

                            player = new Player(options);

                            playerEventHandler = vodPlayerEventHandler(store, notificationHandlers);
                            player.addOnError(playerEventHandler.onError);
                            player.addOnInit(playerEventHandler.onInit);
                            player.addOnDuration(playerEventHandler.onDuration);
                            player.addOnTimeCodeChange(playerEventHandler.onTimeCodeChange);
                            player.addOnPlayStateChange(playerEventHandler.onPlayStateChange);
                            player.addOnVolumeChange(playerEventHandler.onVolumeChange);
                            player.addOnPlaybackNotAllowed(playerEventHandler.onPlaybackNotAllowed);

                            player.setVolume(store.getState().player.volume);
                            player.init(videoElementContainer);
                        }
                    })
                    .catch((err) => {
                        log.error(err);
                    });

                return next(action);

            case PLAYER_SET_SOURCE:
                if (player) {
                    player.setSrc(action.source);
                }
                return next(action);

            case PLAYER_PLAY:
                if (player) {
                    const { skipCounterReset } = action.options;
                    player.play(action.url, skipCounterReset);
                }
                return next(action);

            case PLAYER_PAUSE:
                if (player) {
                    player.pause();
                }
                return next(action);

            case PLAYER_SEEK:
                if (player) {
                    player.seek(action.time * 1000);
                }
                return next(action);

            case PLAYER_STOP:
                if (player) {
                    player.stop();
                }
                return next(action);

            case PLAYER_HIDE:
                if (player) {
                    player.hide();
                }
                return next(action);

            case PLAYER_SHOW:
                if (player) {
                    player.show();
                }
                return next(action);

            case PLAYER_SET_VOLUME:
                if (player) {
                    player.setVolume(action.volume);
                } else {
                    // preset desired volume even if player is not loaded yet
                    store.dispatch(playerVolumeChange(action.volume));
                }
                return next(action);

            case PLAYER_SET_QUALITY_LEVEL:
                if (player) {
                    player.setQualityLevel(action.level);
                }
                return next(action);

            case PLAYER_TOGGLE_FULLSCREEN:
                if (!(Player.isFullscreenSupported && playerElement)) {
                    return next(action);
                }

                if (!store.getState().player.fullscreen) {
                    fullscreenChangeHandlerRef = fullscreenChangeHandler(store);
                    document.addEventListener("fullscreenchange", fullscreenChangeHandlerRef);
                }

                if (
                    !document.fullscreenElement &&
                    !document.mozFullScreenElement &&
                    !document.webkitFullscreenElement &&
                    !document.msFullscreenElement
                ) {
                    if (playerElement.requestFullscreen) {
                        playerElement.requestFullscreen().catch();
                    } else if (playerElement.mozRequestFullScreen) {
                        playerElement.mozRequestFullScreen();
                    } else if (playerElement.webkitRequestFullscreen) {
                        playerElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                    } else if (playerElement.msRequestFullscreen) {
                        playerElement.msRequestFullscreen();
                    }
                } else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen().catch();
                    } else if (document.cancelFullScreen) {
                        document.cancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                }

                return next(action);

            case PLAYER_REFRESH:
                if (!player) {
                    return next(action);
                }

                const source = player.source;
                log.debug("refresh player:", source);
                player.stop();
                player.play(source);
                break;

            case PLAYER_RESET:
                if (!player) {
                    return next(action);
                }
                next(action);

                store.dispatch(playerInit(action.options, action.notificationHandlers));
                break;

            case PLAYER_DESTROY:
                if (player) {
                    player.removeAllCallbacks();
                    player.destroy();
                }
                if (fullscreenChangeHandlerRef) {
                    document.removeEventListener("fullscreenchange", fullscreenChangeHandlerRef);
                }

                player = null;
                playerElement = null;
                videoElementContainer = null;
                playerEventHandler = null;
                playerPubSub = null;

                return next(action);

            default:
                return next(action);
        }
    };
};
