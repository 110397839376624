import { all, put, select, take, takeLeading } from "redux-saga/effects";
import {
    changeAppState,
    getPublicCompositionInfo,
    getPublicProductionInfo,
    getPublicServiceInfo,
    initializeLiveCommunicator,
    initializeVodCommunicator,
    newError,
    urlParametersParsed,
} from "./actions";
import {
    INIT_APP,
    PUBLIC_COMPOSITION_INFO_FAILURE,
    PUBLIC_COMPOSITION_INFO_SUCCESS,
    PUBLIC_PRODUCTION_INFO_FAILURE,
    PUBLIC_PRODUCTION_INFO_SUCCESS,
    PUBLIC_SERVICE_INFO_FAILURE,
    PUBLIC_SERVICE_INFO_SUCCESS,
} from "./actionTypes";

import { Config } from "../../../config/Config";
import { APP_STATE, COMMUNICATOR_MODES, ERRORS, URL_PARAMS } from "../../../constants/constants";
import { log } from "../../base/utils/logger";
import { decodeBase64, parseURLSearchParameters } from "../utils/parseURLSearchParameters";
import { readFromSessionStorage, storeToSessionStorage } from "../../base/utils/localStorage";

const getService = (state) => state.landingPage.service;
const getServiceId = () => {
    let serviceId;
    if (Config.serviceIdFromUrl) {
        serviceId = window.location.pathname
            .split("/")
            .filter((i) => i)
            .pop();
    } else {
        serviceId = Config.serviceId;
    }
    return serviceId;
};

function* parseUrlWorker() {
    const origin = window.location.origin;
    const pathname = window.location.pathname;
    let search = window.location.search;
    let hash = window.location.hash;

    // check if url search parameters are encoded (start of encoded parameters denoted by '#')
    if (hash) {
        // decode base64 encoded url search parameters
        try {
            hash = hash.substring(1);
            search = decodeBase64(hash);
        } catch (e) {
            log.error(e);
        }
    }

    // parse url search parameters
    const params = parseURLSearchParameters(search);
    log.debug("parsed parameters", params);

    // check if some url parameters (for this production) are already stored in session storage
    try {
        let storedParams = readFromSessionStorage("ac-params");
        log.debug("stored parameters", storedParams);

        if (storedParams) storedParams = JSON.parse(storedParams);
        if (storedParams && typeof storedParams === "object" && params.id === storedParams.id) {
            // merge stored parameters with parsed parameters
            for (const key in storedParams) {
                if (!params.hasOwnProperty(key)) continue;
                if (!params[key]) params[key] = storedParams[key];
                else if (key === "userInfo" && storedParams[key] && typeof storedParams[key] === "object") {
                    params[key] = { ...storedParams[key], ...params[key] };
                }
            }
        }
    } catch (e) {
        log.error(e);
    }

    log.debug("merged parameters", params);
    storeToSessionStorage("ac-params", JSON.stringify(params));
    yield put(urlParametersParsed(params));

    if (hash) {
        // remove encoded url parameters from url (except for id and popout parameter)
        let url = `${origin}${pathname}?${URL_PARAMS.ID}=${params.id}`;
        if (params.popout) url += `&${URL_PARAMS.POPOUT}=${params.popout}`;
        window.history.replaceState(null, document.title, url);
    }

    return params;
}

function* initApp() {
    try {
        const { id, preview } = yield* parseUrlWorker();
        const communicatorMode = preview ? COMMUNICATOR_MODES.PREVIEW : COMMUNICATOR_MODES.DEFAULT;

        const serviceId = getServiceId();
        log.debug("serviceId", serviceId);
        if (!serviceId) {
            yield put(changeAppState(APP_STATE.INIT_FAILURE));
            yield put(newError(ERRORS.INVALID_SERVICE));
            return;
        }

        const service = yield select(getService);
        if (!service) {
            // fetch public service info
            yield put(getPublicServiceInfo(serviceId));
            const action = yield take([PUBLIC_SERVICE_INFO_SUCCESS, PUBLIC_SERVICE_INFO_FAILURE]);
            if (action.type === PUBLIC_SERVICE_INFO_FAILURE || !action.publicServiceInfo) {
                yield put(changeAppState(APP_STATE.INIT_FAILURE));
                yield put(newError(action.error.message));
                return;
            }
        }

        log.debug("productionId", id);
        if (!(id && (id.startsWith("p") || id.startsWith("c")))) {
            yield put(changeAppState(APP_STATE.INIT_FAILURE));
            yield put(newError(ERRORS.INVALID_ID));
            return;
        }

        const isComposition = id.startsWith("c");
        if (isComposition) {
            // fetch public composition info
            yield put(getPublicCompositionInfo(serviceId, id));
            const action = yield take([PUBLIC_COMPOSITION_INFO_SUCCESS, PUBLIC_COMPOSITION_INFO_FAILURE]);
            if (action.type === PUBLIC_COMPOSITION_INFO_FAILURE || !action.publicCompositionInfo) {
                yield put(changeAppState(APP_STATE.INIT_FAILURE));
                yield put(newError(action.error.message));
                return;
            }
            yield put(initializeVodCommunicator(communicatorMode, true));
        } else {
            // fetch public production info
            yield put(getPublicProductionInfo(serviceId, id));
            const action = yield take([PUBLIC_PRODUCTION_INFO_SUCCESS, PUBLIC_PRODUCTION_INFO_FAILURE]);
            if (action.type === PUBLIC_PRODUCTION_INFO_FAILURE || !action.publicProductionInfo) {
                yield put(changeAppState(APP_STATE.INIT_FAILURE));
                yield put(newError(action.error));
                return;
            }

            const production = action.publicProductionInfo;
            if (production.type === "live" || production.type === "podium") {
                // check production state
                switch (production.state) {
                    case "open":
                    case "sent":
                        yield put(initializeLiveCommunicator(communicatorMode));
                        break;
                    case "archived":
                        yield put(initializeVodCommunicator(communicatorMode));
                        break;
                    default:
                        // production is deleted
                        yield put(changeAppState(APP_STATE.INIT_FAILURE));
                        yield put(newError());
                }
            } else if (production.type === "ondemand") {
                // check production state
                switch (production.state) {
                    case "open":
                    case "sent":
                        yield put(changeAppState(APP_STATE.INIT_FAILURE));
                        yield put(newError(ERRORS.ON_DEMAND_PRODUCTION));
                        break;
                    case "archived":
                        yield put(initializeVodCommunicator(communicatorMode));
                        break;
                    default:
                        // production is deleted
                        yield put(changeAppState(APP_STATE.INIT_FAILURE));
                        yield put(newError());
                }
            } else {
                yield put(changeAppState(APP_STATE.INIT_FAILURE));
                yield put(newError());
            }
        }
    } catch (e) {
        log.error(e);
        yield put(changeAppState(APP_STATE.INIT_FAILURE));
        yield put(newError(e));
    }
}

function* watchAll() {
    yield all([takeLeading(INIT_APP, initApp)]);
}

export default watchAll;
