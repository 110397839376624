import { notEmpty } from "./validateSignInInputs";

/**
 * trim strings from user info and set defaults if required
 * @param signInInputs
 * @param userInfo
 * @returns {{}}
 */
export const sanitizeUserInfo = (signInInputs, userInfo) => {
    const sanitizedUserInfo = {};
    userInfo = userInfo || {};

    signInInputs.forEach((item) => {
        if (item.required) {
            sanitizedUserInfo[item.name] = notEmpty(userInfo[item.name]) ? userInfo[item.name].trim() : item.default;
        } else if (userInfo.hasOwnProperty(item.name)) {
            sanitizedUserInfo[item.name] = (userInfo[item.name] && userInfo[item.name].trim()) || item.default || null;
        } else {
            sanitizedUserInfo[item.name] = item.default || null;
        }
    });

    return sanitizedUserInfo;
};
