import * as types from "./actionTypes";

// get some default player settings (e.g. volume, qualityLevel) from local storage such that they are preserved
// across page reloads?
export const initialState = {
    loaded: false,
    initialized: false,
    type: null,
    ua: null,

    playbackNotAllowed: false,
    isPlaying: false,

    qualities: [],
    qualityLevel: -1,
    resolution: {},

    volumeSupported: false,
    volume: 0.5,
    prevVolume: 0.5,
    volumeMuted: false,

    fullscreenSupported: false,
    togglingFullscreen: false,
    fullscreen: false,

    streamUrl: null,
    error: null,
};

export default function player(state = initialState, action) {
    switch (action.type) {
        case types.PLAYER_LOADED:
            return {
                ...state,
                loaded: true,
                volumeSupported: action.volumeSupported,
                fullscreenSupported: action.fullscreenSupported,
            };

        case types.PLAYER_INITIALIZED:
            return {
                ...state,
                initialized: true,
                type: action.playerType,
                ua: action.ua,
            };

        case types.PLAYER_PLAY:
            return {
                ...state,
                isPlaying: true,
                playbackNotAllowed: false,
                ...(!state.streamUrl && { streamUrl: action.url }),
            };

        case types.PLAYER_PAUSE:
            return {
                ...state,
                isPlaying: false,
            };

        case types.PLAYER_PLAY_STATE_CHANGE:
            return {
                ...state,
                isPlaying: action.isPlaying,
            };

        case types.PLAYER_AVAILABLE_QUALITIES:
            return {
                ...state,
                qualities: action.qualities,
            };

        case types.PLAYER_QUALITY_LEVEL_CHANGE:
            return {
                ...state,
                qualityLevel: action.level,
            };

        case types.PLAYER_RESOLUTION_CHANGE:
            return {
                ...state,
                resolution: action.resolution,
            };

        case types.PLAYER_VOLUME_CHANGE:
            if (action.volume <= 0) {
                return {
                    ...state,
                    volume: 0,
                    prevVolume: state.volume,
                    volumeMuted: true,
                };
            } else {
                return {
                    ...state,
                    volume: action.volume,
                    volumeMuted: false,
                };
            }

        case types.PLAYER_TOGGLE_FULLSCREEN:
            return {
                ...state,
                togglingFullscreen: true,
            };

        case types.PLAYER_FULLSCREEN_CHANGE:
            return {
                ...state,
                togglingFullscreen: false,
                fullscreen: action.fullscreenActive,
            };

        case types.PLAYER_ERROR:
            return {
                ...state,
                error: action.error,
            };

        case types.PLAYER_PLAYBACK_NOT_ALLOWED:
            return {
                ...state,
                isPlaying: false,
                playbackNotAllowed: true,
            };

        case types.PLAYER_RESET:
            return {
                ...initialState,
                loaded: state.loaded,
                initialized: state.initialized,
                volumeSupported: state.volumeSupported,
                volume: state.volume,
                fullscreenSupported: state.fullscreenSupported,
                fullscreen: state.fullscreen,
            };

        case types.PLAYER_DESTROY:
            return {
                ...initialState,
                loaded: state.loaded,
                volumeSupported: state.volumeSupported,
                fullscreenSupported: state.fullscreenSupported,
            };

        default:
            return state;
    }
}
