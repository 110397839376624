import { Config } from "../../../config/Config";

const isHttps = () => {
    if (!!Config.forceHttps) return true;
    else if (
        Config.name === "local" ||
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
    )
        return false;
    return window.location.protocol === "https:";
};

export default isHttps;
