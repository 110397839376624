export const INIT_APP = "INIT_APP";
export const CHANGE_APP_STATE = "CHANGE_APP_STATE";
export const NEW_ERROR = "NEW_ERROR";
export const URL_PARAMETERS_PARSED = "URL_PARAMETERS_PARSED";

export const PUBLIC_SERVICE_INFO_REQUEST = "PUBLIC_SERVICE_INFO_REQUEST";
export const PUBLIC_SERVICE_INFO_SUCCESS = "PUBLIC_SERVICE_INFO_SUCCESS";
export const PUBLIC_SERVICE_INFO_FAILURE = "PUBLIC_SERVICE_INFO_FAILURE";

export const PUBLIC_PRODUCTION_INFO_SUCCESS = "PUBLIC_PRODUCTION_INFO_SUCCESS";
export const PUBLIC_PRODUCTION_INFO_REQUEST = "PUBLIC_PRODUCTION_INFO_REQUEST";
export const PUBLIC_PRODUCTION_INFO_FAILURE = "PUBLIC_PRODUCTION_INFO_FAILURE";

export const PUBLIC_COMPOSITION_INFO_REQUEST = "PUBLIC_COMPOSITION_INFO_REQUEST";
export const PUBLIC_COMPOSITION_INFO_SUCCESS = "PUBLIC_COMPOSITION_INFO_SUCCESS";
export const PUBLIC_COMPOSITION_INFO_FAILURE = "PUBLIC_COMPOSITION_INFO_FAILURE";

export const CHECK_ACCESS_REQUEST = "CHECK_ACCESS_REQUEST";
export const CHECK_ACCESS_SUCCESS = "CHECK_ACCESS_SUCCESS";
export const CHECK_ACCESS_FAILURE = "CHECK_ACCESS_FAILURE";

export const CHECK_TOKEN_REQUEST = "CHECK_TOKEN_REQUEST";
export const CHECK_TOKEN_SUCCESS = "CHECK_TOKEN_SUCCESS";
export const CHECK_TOKEN_FAILURE = "CHECK_TOKEN_FAILURE";

export const SIGN_IN = "SIGN_IN";
export const ENTER_PRODUCTION = "ENTER_PRODUCTION";
export const SUBMIT_TOKEN = "SUBMIT_TOKEN";

export const INIT_LIVE_COMMUNICATOR = "INIT_LIVE_COMMUNICATOR";
export const INIT_LIVE_COMMUNICATOR_SUCCESS = "INIT_LIVE_COMMUNICATOR_SUCCESS";
export const INIT_LIVE_COMMUNICATOR_FAILURE = "INIT_LIVE_COMMUNICATOR_FAILURE";
export const LEAVE_LIVE_COMMUNICATOR = "LEAVE_LIVE_COMMUNICATOR";

export const INIT_VOD_COMMUNICATOR = "INIT_VOD_COMMUNICATOR";
export const INIT_VOD_COMMUNICATOR_SUCCESS = "INIT_VOD_COMMUNICATOR_SUCCESS";
export const INIT_VOD_COMMUNICATOR_FAILURE = "INIT_VOD_COMMUNICATOR_FAILURE";
export const LEAVE_VOD_COMMUNICATOR = "LEAVE_VOD_COMMUNICATOR";
