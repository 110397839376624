import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/string/includes";

import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";

import "./css/index.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.min.css";

import { createConnectorMiddleware } from "./middleware/connectorMiddleware";
import { createPlayerMiddleware } from "./middleware/playerMiddleware";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

import Communicator from "./Communicator";
import apApiService from "./services/apApiService";
import getPreloadLanguage from "./features/base/i18n/getPreloadLanguage";
import { LanguageContextProvider } from "./features/base/i18n/LanguageContext";
import { Config } from "./config/Config";

const favicon = Config.favicon;
const documentTitle = Config.documentTitle;
const theme = Config.theme;

// import css theme
import(`./css/themes/theme_${theme ? theme : "default"}.scss`).catch((err) => console.error(err));

// create middlewares and store
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    {
        base: {
            i18n: {
                language: getPreloadLanguage(),
            },
        },
    },
    composeEnhancers(
        applyMiddleware(
            thunk.withExtraArgument(apApiService),
            createConnectorMiddleware(),
            createPlayerMiddleware(),
            sagaMiddleware
        )
    )
);
sagaMiddleware.run(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <Helmet>
            {documentTitle ? <title>{documentTitle}</title> : <title>adiaCast Communicator</title>}
            {favicon && <link rel="shortcut icon" href={favicon} />}
        </Helmet>
        <LanguageContextProvider>
            <Communicator />
        </LanguageContextProvider>
    </Provider>,
    document.getElementById("root")
);
