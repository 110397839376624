import UAParser from "ua-parser-js";
import { BROWSER_MIN_VERSIONS } from "../../../constants/constants";
import { log } from "./logger";

const uaParser = new UAParser();
export const uaParserResult = uaParser.getResult();

export const isSupported = () => {
    const res = { browser: false, browserVersion: false };
    try {
        log.debug("isSupported", uaParserResult, BROWSER_MIN_VERSIONS);

        const browserName = uaParserResult.browser.name;
        res.browser = !!(browserName && BROWSER_MIN_VERSIONS.hasOwnProperty(browserName));

        if (uaParserResult.browser.version) {
            const browserVersionParts = uaParserResult.browser.version.split(",")[0].split(".");
            const browserVersion = Number(`${browserVersionParts[0]}.${browserVersionParts[1]}`);
            res.browserVersion = !!(res.browser && BROWSER_MIN_VERSIONS[browserName] <= browserVersion);
        } else {
            res.browserVersion = res.browser;
        }
    } catch (e) {}
    return res;
};
