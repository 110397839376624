import * as types from "./actionTypes";
import { ERRORS } from "../../../constants/constants";
import handleError from "../../base/utils/handleError";

export const initApp = () => ({ type: types.INIT_APP });
export const changeAppState = (appState, options = {}) => ({
    type: types.CHANGE_APP_STATE,
    appState,
    ...options,
});

export const newError = (error) => {
    let newError = ERRORS.DEFAULT_ERROR;
    if (error) {
        if (typeof error === "string") {
            newError.message = error;
        } else if (typeof error === "number") {
            error = Object.values(ERRORS).filter((value) => value.errorNo === error);
            if (error) {
                newError.errorNo = error.errorNo;
                newError.message = error.message;
            }
        } else {
            newError = error;
        }
    }

    return {
        type: types.NEW_ERROR,
        errorNo: newError.errorNo,
        errorMessage: newError.message,
    };
};

export const urlParametersParsed = (params) => ({
    type: types.URL_PARAMETERS_PARSED,
    params,
});

const getPublicServiceInfoRequest = () => ({
    type: types.PUBLIC_SERVICE_INFO_REQUEST,
});
const getPublicServiceInfoSuccess = (publicServiceInfo) => ({
    type: types.PUBLIC_SERVICE_INFO_SUCCESS,
    publicServiceInfo,
});
const getPublicServiceInfoFailure = (error) => ({
    type: types.PUBLIC_SERVICE_INFO_FAILURE,
    error,
});
export const getPublicServiceInfo = (serviceId) => {
    return async (dispatch, getState, api) => {
        dispatch(getPublicServiceInfoRequest());

        const response = await api.getPublicServiceInfo(serviceId);
        if (!response.error) {
            dispatch(getPublicServiceInfoSuccess(response.publicServiceInfo));
        } else {
            handleError(response.error, (err) => dispatch(getPublicServiceInfoFailure(err)));
        }
    };
};

const getPublicProductionInfoRequest = () => ({
    type: types.PUBLIC_PRODUCTION_INFO_REQUEST,
});
const getPublicProductionInfoSuccess = (publicProductionInfo) => ({
    type: types.PUBLIC_PRODUCTION_INFO_SUCCESS,
    publicProductionInfo,
});
const getPublicProductionInfoFailure = (error) => ({
    type: types.PUBLIC_PRODUCTION_INFO_FAILURE,
    error,
});
export const getPublicProductionInfo = (serviceId, productionId) => {
    return async (dispatch, getState, api) => {
        dispatch(getPublicProductionInfoRequest());

        const response = await api.getPublicProductionInfo(serviceId, productionId);
        if (!response.error) {
            dispatch(getPublicProductionInfoSuccess(response.publicProductionInfo));
        } else {
            handleError(response.error, (err) => dispatch(getPublicProductionInfoFailure(err)));
        }
    };
};

const getPublicCompositionInfoRequest = () => ({
    type: types.PUBLIC_COMPOSITION_INFO_REQUEST,
});
const getPublicCompositionInfoSuccess = (publicCompositionInfo) => ({
    type: types.PUBLIC_COMPOSITION_INFO_SUCCESS,
    publicCompositionInfo,
});
const getPublicCompositionInfoFailure = (error) => ({
    type: types.PUBLIC_COMPOSITION_INFO_FAILURE,
    error,
});
export const getPublicCompositionInfo = (serviceId, compositionId) => {
    return async (dispatch, getState, api) => {
        dispatch(getPublicCompositionInfoRequest());

        const response = await api.getPublicCompositionInfo(serviceId, compositionId);
        if (!response.error) {
            dispatch(getPublicCompositionInfoSuccess(response.publicCompositionInfo));
        } else {
            handleError(response.error, (err) => dispatch(getPublicCompositionInfoFailure(err)));
        }
    };
};

const checkAccessRequest = () => ({
    type: types.CHECK_ACCESS_REQUEST,
});
const checkAccessSuccess = (result) => ({
    type: types.CHECK_ACCESS_SUCCESS,
    result,
});
const checkAccessFailure = (error) => ({
    type: types.CHECK_ACCESS_FAILURE,
    error,
});
export const checkAccess = (serviceId, id, token) => {
    return async (dispatch, getState, api) => {
        dispatch(checkAccessRequest());

        const response = await api.checkProductionAccess(serviceId, id, token, ["ipRestrictions", "availability"]);
        if (!response.error) {
            dispatch(checkAccessSuccess(response.result));
        } else {
            handleError(response.error, (err) => dispatch(checkAccessFailure(err)));
        }
    };
};

const checkTokenRequest = () => ({
    type: types.CHECK_TOKEN_REQUEST,
});
const checkTokenSuccess = (valid, token) => ({
    type: types.CHECK_TOKEN_SUCCESS,
    valid,
    token,
});
const checkTokenFailure = (error) => ({
    type: types.CHECK_TOKEN_FAILURE,
    error,
});
export const checkToken = (isComposition, serviceId, id, token) => {
    return async (dispatch, getState, api) => {
        dispatch(checkTokenRequest());

        let response;
        if (isComposition) {
            response = await api.checkCompositionToken(serviceId, id, token);
        } else {
            response = await api.checkProductionToken(serviceId, id, token);
        }

        if (!response.error) {
            dispatch(checkTokenSuccess(response.valid, token));
        } else {
            handleError(response.error, (err) => dispatch(checkTokenFailure(err)));
        }
    };
};

export const submitToken = (token) => ({ type: types.SUBMIT_TOKEN, token });
export const signIn = (userInfo) => ({ type: types.SIGN_IN, userInfo });
export const enterProduction = () => ({ type: types.ENTER_PRODUCTION });

export const initializeLiveCommunicator = (communicatorMode) => ({
    type: types.INIT_LIVE_COMMUNICATOR,
    communicatorMode,
});
export const initializeLiveCommunicatorSuccess = () => ({ type: types.INIT_LIVE_COMMUNICATOR_SUCCESS });
export const initializeLiveCommunicatorFailure = (error) => ({ type: types.INIT_LIVE_COMMUNICATOR_FAILURE, error });
export const leaveLiveCommunicator = () => ({ type: types.LEAVE_LIVE_COMMUNICATOR });

export const initializeVodCommunicator = (communicatorMode, isComposition = false) => ({
    type: types.INIT_VOD_COMMUNICATOR,
    communicatorMode,
    isComposition,
});
export const initializeVodCommunicatorSuccess = () => ({ type: types.INIT_VOD_COMMUNICATOR_SUCCESS });
export const initializeVodCommunicatorFailure = (error) => ({ type: types.INIT_VOD_COMMUNICATOR_FAILURE, error });
export const leaveVodCommunicator = () => ({ type: types.LEAVE_VOD_COMMUNICATOR });
