import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";

import "./serenity/ripple";
import throttle from "lodash/throttle";

import { initApp, leaveLiveCommunicator, leaveVodCommunicator } from "./features/landingPage/redux/actions";
import { changeResponsiveMode } from "./features/base/global/actions";
import { changeLanguage } from "./features/base/i18n/redux/actions";
import { log } from "./features/base/utils/logger";
import { isSupported } from "./features/base/utils/isSupported";
import { APP_STATE, PLAYER_MODES, RESPONSIVE_MODES } from "./constants/constants";
import { Config } from "./config/Config";

import LoadingScreen from "./features/base/loadingScreen/LoadingScreen";

const LandingPage = lazy(() => import("./features/landingPage/LandingPage"));
const LiveCommunicator = lazy(() => import("./features/liveCommunicator/LiveCommunicator"));
const VodCommunicator = lazy(() => import("./features/vodCommunicator/VodCommunicator"));

class Communicator extends Component {
    constructor(props) {
        super(props);

        this.isSupportedResult = isSupported();
        this.state = {
            isSupported: this.isSupportedResult.browser && this.isSupportedResult.browserVersion,
        };

        this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
        this.handleWindowResizeEvent = this.handleWindowResizeEvent.bind(this);
        this.handleWindowResizeEventThrottled = throttle(this.handleWindowResizeEvent, 100);
    }

    componentDidMount() {
        log.debug("config", Config);
        log.debug("env", process.env);

        window.addEventListener("beforeunload", this.handleBeforeUnload);
        window.addEventListener("resize", this.handleWindowResizeEventThrottled);
        window.changeLanguage = (language) => {
            this.props.changeLanguage(language);
        };

        this.handleWindowResizeEvent();

        if (this.state.isSupported) {
            this.props.initApp();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.playerFullscreen && !this.props.playerFullscreen) {
            this.handleWindowResizeEvent();
        }
    }

    componentWillUnmount() {
        this.handleBeforeUnload();

        window.removeEventListener("resize", this.handleWindowResizeEventThrottled);
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
        window.changeLanguage = null;
    }

    handleBeforeUnload() {
        this.props.leaveLiveCommunicator();
        this.props.leaveVodCommunicator();
    }

    handleWindowResizeEvent() {
        if (!this.props.playerTogglingFullscreen && !this.props.playerFullscreen) {
            const newWidth = document.body.clientWidth;
            let newResponsiveMode;

            if (newWidth < RESPONSIVE_MODES.FULL) {
                newResponsiveMode = RESPONSIVE_MODES.SMALL;
            } else {
                newResponsiveMode = RESPONSIVE_MODES.FULL;
            }

            if (this.props.responsiveMode !== newResponsiveMode) {
                this.props.changeResponsiveMode(newResponsiveMode);
            }
        }
    }

    render() {
        if (!this.state.isSupported) {
            return (
                <Suspense fallback={<LoadingScreen />}>
                    <LandingPage isSupported={this.isSupportedResult} />
                </Suspense>
            );
        } else if (this.props.appState === APP_STATE.INIT_FAILURE) {
            return (
                <Suspense fallback={<LoadingScreen />}>
                    <LandingPage />
                </Suspense>
            );
        } else if (this.props.playerMode) {
            return (
                <Suspense fallback={<LoadingScreen />}>
                    {this.props.playerMode === PLAYER_MODES.VOD ? <VodCommunicator /> : <LiveCommunicator />}
                </Suspense>
            );
        }

        return <LoadingScreen />;
    }
}

const mapStateToProps = (state) => {
    return {
        appState: state.landingPage.appState,
        playerMode: state.landingPage.playerMode,
        playerTogglingFullscreen: state.player.togglingFullscreen,
        playerFullscreen: state.player.fullscreen,
        responsiveMode: state.base.global.responsiveMode,
    };
};

const mapDispatchToProps = {
    initApp,
    changeResponsiveMode,
    changeLanguage,
    leaveLiveCommunicator,
    leaveVodCommunicator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Communicator);
