export const english = {
    language: "Language",

    de: "German",
    en: "English",
    fr: "French",
    it: "Italian",

    // Player Panel
    offline: "OFFLINE",
    online: "ONLINE",
    posterTextPre: "The broadcast will start shortly.",
    posterTextPost: "The broadcast has ended. Thank you for watching!",
    overlayTextPlayerError:
        "Your browser or device does not support the playback of this broadcast.\nPlease use an alternative browser or a different device.",

    // Chat Panel
    chatHeader: "Stream Chat",
    chatTabChat: "Chat",
    chatTabPolls: "Polls",
    chatTabDocuments: "Documents",
    chatHide: "Hide Chat",
    chatPopout: "Pop-Out Chat",
    chatDisabledText: "The chat is deactivated.",
    chatPopoutText: "The chat is in Pop-Out mode.",
    chatPopoutShow: "Show Pop-Out",
    chatPopoutClose: "Close Pop-Out",

    // Description Panel
    moderator: "Moderator",
    direction: "Direction",
    company: "Company",
    descriptionNotAvailable: "No description available",

    // Landing Page
    browserNotSupportedText: "Your browser is not supported. Please use an alternative browser.",
    browserVersionNotSupportedText:
        "This version of your browser is no longer supported. Please update your browser or use an alternative browser.",

    signInPanelSignIn: "Sign In",
    signInPanelName: "Name",
    termsOfUse: "Terms of Use",
    acceptTermsOfUse: "I accept the terms of use.",
    legalNotice: "Legal Notice",

    infoPanelArchiveText: "The broadcast has been archived. To watch the archived broadcast click the button below.",
    infoPanelArchiveTextLiveOnly: "This broadcast has ended and the recording will not be published.",
    infoPanelArchiveNext: "Watch Archived Broadcast",
    infoPanelWaitingText: "This broadcast is not ready yet. Please wait until it is made available by the direction.",
    infoPanelScheduleText1: "This broadcast will be available in:",
    infoPanelScheduleText2: "This broadcast is not available anymore.",
    tokenInputPanelText: "This broadcast is secured by an access token. Please enter the correct token to access it.",
    tokenInputPanelToken: "Access Token",
    tokenInputPanelAuthenticate: "Submit",
    tokenInputPanelTokenError: "The entered access token is incorrect!",
    accessDeniedText: "You do not have access to this broadcast.",
    errorPanelDefaultText: "Oops! Something went wrong.",
    errorPanelInvalidIdText: "This broadcast does not exist.",
    errorPanelOnDemandProductionText: "This broadcast has yet to be published.",

    errorPanelLiveOnlyProduction: "This broadcast has ended and the recording will not be published.",
};
