import { german } from "./languages/german";
import { english } from "./languages/english";
import { french } from "./languages/french";
import { italian } from "./languages/italian";
import { Config } from "../../../config/Config";

export const languages = {
    GERMAN: "de",
    ENGLISH: "en",
    FRENCH: "fr",
    ITALIAN: "it",
};

const translations = {
    [languages.ENGLISH]:
        Config.languageOverrides && Config.languageOverrides.en
            ? { ...english, ...Config.languageOverrides.en }
            : english,
    [languages.GERMAN]:
        Config.languageOverrides && Config.languageOverrides.de
            ? { ...german, ...Config.languageOverrides.de }
            : german,
    [languages.FRENCH]:
        Config.languageOverrides && Config.languageOverrides.fr
            ? { ...french, ...Config.languageOverrides.fr }
            : french,
    [languages.ITALIAN]:
        Config.languageOverrides && Config.languageOverrides.it
            ? { ...italian, ...Config.languageOverrides.it }
            : italian,
};

export const hasLanguage = (language) => Object.values(languages).includes(language);

export const getTranslatedString = (language, id) => translations[language][id];
