import * as types from "./actionTypes";

export const initialState = {
    playerMode: "theatre",
};

export default function vodPlayer(state = initialState, action) {
    switch (action.type) {
        case types.TOGGLE_PLAYER_MODE:
            return {
                ...state,
                playerMode: state.playerMode === "default" ? "theatre" : "default",
            };

        default: {
            return state;
        }
    }
}
