const getParameterFromHref = (name) => {
    //name = decodeURI(name);
    const href = decodeURI(window.location.href);
    name = name
        .replace(/[\[]/, "\\[") /* eslint-disable-line */
        .replace(/[\]]/, "\\]"); /* eslint-disable-line */
    const regexS = "[\\?&]" + name + "=([^&#]*)";
    const regex = new RegExp(regexS);
    const results = regex.exec(href);

    return !Array.isArray(results) ? "" : results[1];
};

export default getParameterFromHref;
