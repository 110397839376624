import getParameterFromHref from "./getParameterFromHref";

const Logger = require("loglevelnext/lib/LogLevel");

export let logLevel = "warn";
export let logLevelKeldano = "error";

const debug = getParameterFromHref("debug");
const debugKeldano = getParameterFromHref("debugKeldano");

if (debug === "1" || debug === "true") {
    logLevel = "debug";
}
if (debugKeldano === "1" || debugKeldano === "true") {
    logLevelKeldano = "debug";
}

export const log = new Logger({
    level: logLevel,
    prefix: "{{time}} {{level}}",
});
