export const storeToLocalStorage = (field, data) => {
    try {
        localStorage.setItem(field, data);
        return true;
    } catch (e) {
        return false;
    }
};

export const readFromLocalStorage = (field) => {
    try {
        return localStorage.getItem(field);
    } catch (e) {
        return null;
    }
};

export const storeToSessionStorage = (field, data) => {
    try {
        sessionStorage.setItem(field, data);
        return true;
    } catch (e) {
        return false;
    }
};

export const readFromSessionStorage = (field) => {
    try {
        return sessionStorage.getItem(field);
    } catch (e) {
        return null;
    }
};

export const removeFromSessionStorage = (field) => {
    try {
        sessionStorage.removeItem(field);
        return true;
    } catch (e) {
        return false;
    }
};
