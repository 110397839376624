import favicon from "../assets/favicons/favicon_bekb.ico";

export const Config = {
    serviceUrl: "https://ac-app01.adia.tv",
    serviceId: "kilchenmann",
    serviceIdFromUrl: false,

    keldanoDomain: "ac-keldano01.adia.tv",

    favicon,
    documentTitle: "BEKB Live",
};
