import React, { Component } from "react";

export default class LoadingScreen extends Component {
    constructor(props) {
        super(props);
        this.state = { displaySpinner: false };
    }

    componentDidMount() {
        this.timeout = setTimeout(() => this.setState({ displaySpinner: true }), 500);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        if (this.state.displaySpinner) {
            return (
                <div className="app-loading">
                    <div className="circle-container">
                        <div className="circle-inner" />
                        <div className="circle-outer" />
                    </div>
                </div>
            );
        }

        return null;
    }
}

export function Spinner() {
    return (
        <div className="circle-container">
            <div className="circle-inner" />
            <div className="circle-outer" />
        </div>
    );
}
