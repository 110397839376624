import favicon from "../assets/favicons/favicon_vema.ico";

export const Config = {
    serviceUrl: "https://ac-app01.adia.tv",
    serviceId: "VEMA",
    serviceIdFromUrl: false,

    keldanoDomain: "ac-keldano01.adia.tv",

    favicon,
    documentTitle: "VEMA-TV",
};
