export const german = {
    language: "Sprache",

    de: "Deutsch",
    en: "Englisch",
    fr: "Französisch",
    it: "Italienisch",

    // Player Panel
    offline: "OFFLINE",
    online: "ONLINE",
    posterTextPre: "Die Sendung wird in Kürze beginnen.",
    posterTextPost: "Die Sendung wurde beendet. Vielen Dank fürs Zuschauen!",
    overlayTextPlayerError:
        "Das Abspielen dieser Sendung wird von Ihrem Browser oder Gerät nicht unterstützt.\nBitte verwenden Sie einen alternativen Browser oder ein anderes Gerät.",

    // Chat Panel
    chatHeader: "Stream Chat",
    chatTabChat: "Chat",
    chatTabPolls: "Umfragen",
    chatTabDocuments: "Dokumente",
    chatHide: "Chat ausblenden",
    chatPopout: "Pop-Out-Chat",
    chatDisabledText: "Der Chat ist deaktiviert.",
    chatPopoutText: "Der Chat befindet sich im Pop-Out Modus.",
    chatPopoutShow: "Pop-Out anzeigen",
    chatPopoutClose: "Pop-Out schliessen",

    // Description Panel
    moderator: "Moderator",
    direction: "Regie",
    company: "Firma",
    descriptionNotAvailable: "Keine Beschreibung verfügbar",

    // Landing Page
    browserNotSupportedText: "Ihr Browser wird nicht unterstützt. Bitte verwenden Sie einen alternativen Browser.",
    browserVersionNotSupportedText:
        "Die Version Ihres Browsers wird nicht mehr unterstützt. Bitte aktualisieren Sie Ihren Browser oder verwenden Sie einen alternativen Browser.",

    signInPanelSignIn: "Anmelden",
    signInPanelName: "Name",
    termsOfUse: "Nutzungsbedingungen",
    acceptTermsOfUse: "Ich akzeptiere die Nutzungsbedingungen.",
    legalNotice: "Impressum",

    infoPanelArchiveText:
        "Die Sendung wurde archiviert. Um die archivierte Sendung anzusehen, klicken Sie auf den untenstehenden Button.",
    infoPanelArchiveTextLiveOnly: "Diese Sendung wurde beendet und die Aufzeichnung wird nicht veröffentlicht.",
    infoPanelArchiveNext: "Archivierte Sendung ansehen",
    infoPanelWaitingText:
        "Diese Sendung ist noch nicht bereit. Bitte warten Sie, bis die Sendung von der Regie" +
        " freigeschaltet wird.",
    infoPanelScheduleText1: "Diese Sendung wird verfügbar sein in:",
    infoPanelScheduleText2: "Diese Sendung ist nicht mehr verfügbar.",
    tokenInputPanelText:
        "Diese Sendung ist durch ein Zugriffstoken gesichert. Bitte geben Sie das korrekte Token ein, um die Sendung" +
        " anzusehen.",
    tokenInputPanelToken: "Zugriffstoken",
    tokenInputPanelAuthenticate: "Senden",
    tokenInputPanelTokenError: "Das gesendete Zugriffstoken ist nicht korrekt!",
    accessDeniedText: "Diese Sendung ist für Sie nicht zugänglich.",
    errorPanelDefaultText: "Hoppla! Ein Fehler ist aufgetreten.",
    errorPanelInvalidIdText: "Diese Sendung existiert nicht.",
    errorPanelOnDemandProductionText: "Diese Sendung wurde noch nicht veröffentlicht.",

    errorPanelLiveOnlyProduction: "Diese Sendung wurde beendet und die Aufzeichnung wird nicht veröffentlicht.",
};
