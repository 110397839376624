import { hasLanguage } from "./translations";
import { readFromLocalStorage } from "../utils/localStorage";
import getParameterFromHref from "../utils/getParameterFromHref";

const getPreloadLanguage = () => {
    let language;

    const languageParameter = getParameterFromHref("lang");
    if (languageParameter && hasLanguage(languageParameter)) {
        language = languageParameter;
    } else {
        language = readFromLocalStorage("ac-language");
    }

    if (!language) {
        let userSystemLanguage = navigator.language || navigator.userLanguage;
        if (userSystemLanguage) {
            userSystemLanguage = userSystemLanguage.slice(0, 2);
            if (hasLanguage(userSystemLanguage)) {
                language = userSystemLanguage;
            }
        }
    }

    // if language is still no set, set it to English by default
    if (!language || !hasLanguage(language)) {
        language = "en";
    }
    return language;
};

export default getPreloadLanguage;
