import * as types from "./actionTypes";

export const playerLoad = (playerElement, videoElementContainer) => ({
    type: types.PLAYER_LOAD,
    playerElement,
    videoElementContainer,
});
export const playerLoaded = (fullscreenSupported, volumeSupported) => ({
    type: types.PLAYER_LOADED,
    fullscreenSupported,
    volumeSupported,
});
export const playerInit = (options, notificationHandlers) => ({
    type: types.PLAYER_INIT,
    options,
    notificationHandlers,
});
export const playerInitialized = (playerType, ua) => ({
    type: types.PLAYER_INITIALIZED,
    playerType,
    ua,
});
export const playerSetSource = (source) => ({
    type: types.PLAYER_SET_SOURCE,
    source,
});
export const playerPlay = (url, options = {}) => {
    return {
        type: types.PLAYER_PLAY,
        url,
        options,
    };
};
export const playerPause = () => ({ type: types.PLAYER_PAUSE });
export const playerSeek = (time) => ({ type: types.PLAYER_SEEK, time });
export const playerStop = () => ({ type: types.PLAYER_STOP });
export const playerPlayStateChange = (isPlaying) => ({
    type: types.PLAYER_PLAY_STATE_CHANGE,
    isPlaying,
});
export const playerShow = () => ({ type: types.PLAYER_SHOW });
export const playerHide = () => ({ type: types.PLAYER_HIDE });
export const playerVisibilityChange = () => ({
    type: types.PLAYER_VISIBILITY_CHANGE,
});
export const playerSetVolume = (volume) => ({
    type: types.PLAYER_SET_VOLUME,
    volume,
});
export const playerVolumeChange = (volume) => ({
    type: types.PLAYER_VOLUME_CHANGE,
    volume,
});
export const playerAvailableQualities = (qualities) => ({
    type: types.PLAYER_AVAILABLE_QUALITIES,
    qualities,
});
export const playerSetQualityLevel = (level) => ({
    type: types.PLAYER_SET_QUALITY_LEVEL,
    level,
});
export const playerQualityLevelChange = (level) => ({
    type: types.PLAYER_QUALITY_LEVEL_CHANGE,
    level,
});
export const playerResolutionChange = (resolution) => ({
    type: types.PLAYER_RESOLUTION_CHANGE,
    resolution,
});
export const playerToggleFullscreen = () => ({
    type: types.PLAYER_TOGGLE_FULLSCREEN,
});
export const playerFullscreenChange = (fullscreenActive) => ({
    type: types.PLAYER_FULLSCREEN_CHANGE,
    fullscreenActive,
});
export const playerError = (error) => ({
    type: types.PLAYER_ERROR,
    error,
});
export const playerPlaybackNotAllowed = () => ({
    type: types.PLAYER_PLAYBACK_NOT_ALLOWED,
});
export const playerRefresh = () => ({ type: types.PLAYER_REFRESH });
export const playerReset = (options, notificationHandlers) => ({
    type: types.PLAYER_RESET,
    options,
    notificationHandlers,
});
export const playerDestroy = () => ({ type: types.PLAYER_DESTROY });
