import { URL_PARAMS } from "../../../constants/constants";

/**
 * encode a string as base64
 * @param string
 * @returns {string}
 */
export const encodeBase64 = (string = "") => {
    return btoa(string);
};

/**
 * decode base64 string
 * @param base64String
 * @returns {string}
 */
export const decodeBase64 = (base64String = "") => {
    //return base64url.decode(base64String);
    return atob(base64String);
};

export const encodeSearchParameter = (value) => encodeURIComponent(value);
export const decodeSearchParameter = (value) => decodeURIComponent(value);

/**
 * parse and decode adiaCast URL search parameters
 * @param searchParams
 * @returns {{preview: (""|boolean), userInfo: ({}|null), popout: string, auth: string, id: string}}
 */
export const parseURLSearchParameters = (searchParams) => {
    const urlSearchParams = new URLSearchParams(searchParams);

    // parse parameters supported by default
    const params = {
        id: urlSearchParams.get(URL_PARAMS.ID),
        popout: urlSearchParams.get(URL_PARAMS.POPOUT),
        preview:
            urlSearchParams.get(URL_PARAMS.PREVIEW) &&
            (urlSearchParams.get(URL_PARAMS.PREVIEW) === "1" || urlSearchParams.get(URL_PARAMS.PREVIEW) === "true"),
        auth: urlSearchParams.get(URL_PARAMS.AUTH),
        userInfo: null,
    };

    // parse user info parameters
    const userInfo = {
        name: urlSearchParams.get(URL_PARAMS.USER_NAME),
    };
    urlSearchParams.forEach((value, key) => {
        // skip already parsed parameters
        if (params.hasOwnProperty(key) || key === URL_PARAMS.USER_NAME) return;

        // parse custom user info parameters
        if (key.startsWith("u") && key.length > 1) {
            key = `${key.substring(1).charAt(0).toLowerCase()}${key.substring(2)}`;
            userInfo[key] = value;
        }
    });
    // set user info only if there is at least one user info parameter set
    if (Object.values(userInfo).filter((value) => value !== null).length > 0) {
        params.userInfo = userInfo;
    }

    // decode URL-encoded parameters
    Object.keys(params).forEach((key) => {
        if (typeof params[key] === "string") params[key] = decodeSearchParameter(params[key]);
    });

    return params;
};
