import * as types from "./actionTypes";
import { hasLanguage } from "../translations";
import { storeToLocalStorage } from "../../utils/localStorage";

export function changeLanguage(language) {
    return async (dispatch) => {
        // check if this language is available
        if (hasLanguage(language)) {
            storeToLocalStorage("ac-language", language);
            dispatch({ type: types.CHANGE_LANGUAGE, language });
        }
    };
}
