export const notEmpty = (s) => Boolean(s && typeof s === "string" && s.trim());

export const validateSignInInputs = (signInInputs, inputValues) => {
    const invalidInputs = signInInputs.reduce((acc, curr) => {
        // don't validate hidden inputs
        if (!curr.show) return acc;

        const inputValue = inputValues[curr.name];
        let invalidInput = false;

        // make sure input is not empty if required
        if (curr.required) invalidInput = !notEmpty(inputValue);

        // validate input with custom validator
        if (curr.validator) invalidInput ||= !curr.validator.test(inputValue);

        acc[curr.name] = invalidInput;
        return acc;
    }, {});

    const hasInvalidInputs = Object.values(invalidInputs).some((field) => !!field);
    return [hasInvalidInputs, invalidInputs];
};
