/**
 * @param error - contains error message and status
 * @param onError - onError callbacks:
 *                      fatal: dispatch(forceLogout)
 *                      nonFatal: dispatch(requestFailure)
 */
const handleError = (error, onError) => {
    if (typeof onError !== "object" && typeof onError === "function") {
        const callback = onError;
        onError = {
            fatal: callback,
            nonFatal: callback,
        };
    }

    if (
        !error.hasOwnProperty("internalError") &&
        (!error.hasOwnProperty("errorNo") || !error.hasOwnProperty("status") || !error.hasOwnProperty("message"))
    ) {
        error = {
            errorNo: undefined,
            status: undefined,
            message: "unknown error",
        };
    }

    // 401/403 responses are treated as fatal errors and will lead to a forced logout of the user
    if (error.status === 401 || error.status === 403) {
        onError.fatal(error);
    } else {
        onError.nonFatal(error);
    }
};

export default handleError;
