import Connector, { Contexts } from "@adiacast/connector";
//import Connector, { Contexts } from "../libs/@adiacast/connector/src/connector";
import {
    CONNECTOR_CLOSE,
    CONNECTOR_CONNECT_AS_USER,
    CONNECTOR_CONNECT_TO_PRODUCTION,
    CONNECTOR_DESTROY,
    CONNECTOR_INIT,
    CONNECTOR_SET_CLIENT_STATE_ADD,
    CONNECTOR_SET_CLIENT_STATE_REMOVE,
} from "../features/connector/actionTypes";
import {
    connectorActions,
    connectorClientInfo,
    connectorClients,
    connectorClientState,
    connectorConnectFailure,
    connectorConnectSuccess,
    connectorConnectToProductionFailure,
    connectorConnectToProductionSuccess,
    connectorDirectionInfo,
    connectorInitFailure,
    connectorInitSuccess,
    connectorProductionInfo,
    connectorProductions,
    connectorProductionState,
    connectorServiceError,
    connectorSocketError,
    connectorSocketState,
    connectorStreamInfo,
    connectorUpdateServerTime,
} from "../features/connector/actions";
import { log, logLevel } from "../features/base/utils/logger";

const createConnectorEventHandler = (store, connector, notificationHandlers) => ({
    onSocketError(event, data) {
        log.debug(event, data);
        store.dispatch(connectorSocketError(data.error));
    },
    onServiceError(event, data) {
        log.debug(event, data);
        switch (data.context) {
            case Contexts.INITIALIZE:
                store.dispatch(connectorInitFailure(data.error));
                break;

            case Contexts.CONNECT:
                store.dispatch(connectorConnectFailure(data.error));
                break;

            case Contexts.CONNECT_TO_PRODUCTION:
                store.dispatch(connectorConnectToProductionFailure(data.error));
                break;

            default:
                store.dispatch(connectorServiceError(data.error));
        }
    },
    onActionSuccess(event, data) {
        log.debug(event, data);
        switch (data.context) {
            case Contexts.INITIALIZE:
                store.dispatch(connectorUpdateServerTime(connector.serverTime));
                store.dispatch(connectorInitSuccess());
                break;

            case Contexts.RECONNECT:
                store.dispatch(connectorUpdateServerTime(connector.serverTime));
                break;

            case Contexts.CONNECT:
                store.dispatch(connectorConnectSuccess());
                break;

            case Contexts.CONNECT_TO_PRODUCTION:
                store.dispatch(connectorConnectToProductionSuccess());
                break;

            default:
        }
    },
    onSocketState(event, data) {
        log.debug(event, data);
        store.dispatch(connectorSocketState(data.socketState));
    },
    onClientState(event, data) {
        log.debug(event, data);
        store.dispatch(connectorClientState(data.clientState));
    },
    onClientInfo(event, data) {
        log.debug(event, data);
        store.dispatch(connectorClientInfo(data.clientInfo));
    },
    onProductions(event, data) {
        log.debug(event, data);
        store.dispatch(connectorProductions(data.productions));
    },
    onProductionState(event, data) {
        log.debug(event, data);
        store.dispatch(connectorProductionState(data.productionState, data.changes));
    },
    onProductionInfo(event, data) {
        log.debug(event, data);
        store.dispatch(connectorProductionInfo(data.productionInfo, data.changes));
    },
    onDirectionInfo(event, data) {
        log.debug(event, data);
        store.dispatch(connectorDirectionInfo(data.directionInfo, data.changes));
    },
    onStreamInfo(event, data) {
        log.debug(event, data);
        store.dispatch(connectorStreamInfo(data.streamInfo, data.changes));
    },
    onClients(event, data) {
        log.debug(event, data);
        const clientLists = {};
        for (let list in data.changedLists) {
            if (data.changedLists[list]) {
                clientLists[list] = data.sortedClients[list];
            }
        }
        store.dispatch(connectorClients(clientLists));
    },
    onActions(event, data) {
        log.debug(event, data);
        store.dispatch(connectorActions(data.actions));
    },
    onOnAirTimeChange(event, data) {
        log.debug(event, data);
        if (typeof notificationHandlers.onOnAirTimeChange === "function") {
            notificationHandlers.onOnAirTimeChange(data);
        }
    },
    onNotification(event, data) {
        log.debug(event, data);
    },
});

export const createConnectorMiddleware = () => {
    let connector;
    let connectorEventHandler;

    return (store) => (next) => (action) => {
        switch (action.type) {
            case CONNECTOR_INIT: {
                try {
                    if (connector) {
                        connector.destroy();
                    }

                    connector = new Connector(action.serviceUrl, action.serviceId, {
                        logLevel: logLevel,
                    });

                    const notificationHandlers = action.notificationHandlers || {};
                    connectorEventHandler = createConnectorEventHandler(store, connector, notificationHandlers);

                    connector.addOnSocketError(connectorEventHandler.onSocketError);
                    connector.addOnServiceError(connectorEventHandler.onServiceError);
                    connector.addOnActionSuccess(connectorEventHandler.onActionSuccess);
                    connector.addOnSocketState(connectorEventHandler.onSocketState);
                    connector.addOnClientState(connectorEventHandler.onClientState);
                    connector.addOnClientInfo(connectorEventHandler.onClientInfo);
                    connector.addOnProductions(connectorEventHandler.onProductions);
                    connector.addOnProductionState(connectorEventHandler.onProductionState);
                    connector.addOnProductionInfo(connectorEventHandler.onProductionInfo);
                    connector.addOnStreamInfo(connectorEventHandler.onStreamInfo);
                    connector.addOnOnAirTimeChange(connectorEventHandler.onOnAirTimeChange);
                    connector.addOnNotification(connectorEventHandler.onNotification);

                    connector.init();
                } catch (e) {
                    connector = null;
                    log.error(e);
                }

                return next(action);
            }

            case CONNECTOR_CONNECT_AS_USER:
                if (connector) {
                    connector.connectAsUser(action.clientId, action.info, action.modes, action.metadata);
                }

                return next(action);

            case CONNECTOR_CONNECT_TO_PRODUCTION:
                if (connector) {
                    connector.connectToProduction(action.productionId, action.token);
                }

                return next(action);

            case CONNECTOR_SET_CLIENT_STATE_ADD:
                if (connector) {
                    connector.setClientStateAdd(action.state);
                }

                return next(action);

            case CONNECTOR_SET_CLIENT_STATE_REMOVE:
                if (connector) {
                    connector.setClientStateRemove(action.state);
                }

                return next(action);

            case CONNECTOR_CLOSE:
                if (connector) {
                    connector.close();
                }

                return next(action);

            case CONNECTOR_DESTROY:
                if (connector) {
                    connector.removeAllCallbacks();
                    connector.destroy();
                    connector = null;
                }

                return next(action);

            default:
                return next(action);
        }
    };
};
