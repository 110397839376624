export const french = {
    language: "Langue",

    de: "Allemand",
    en: "Anglais",
    fr: "Français",
    it: "Italien",

    // Player Panel
    offline: "OFFLINE",
    online: "ONLINE",
    posterTextPre: "L'émission débuta bientôt",
    posterTextPost: "L'émission est terminée. Merci de l'avoir regardée!",
    overlayTextPlayerError:
        "Votre navigateur ou votre appareil ne prend pas en charge la lecture de cette émission.\nVeuillez utiliser un autre navigateur ou un autre appareil.",

    // Chat Panel
    chatHeader: "Stream Chat",
    chatTabChat: "Chat",
    chatTabPolls: "Sondages",
    chatTabDocuments: "Documents",
    chatHide: "Cacher le chat",
    chatPopout: "Pop-Out le chat",
    chatDisabledText: "Le chat est désactivé.",
    chatPopoutText: "Le chat est en mode Pop-Out.",
    chatPopoutShow: "Afficher le Pop-Out",
    chatPopoutClose: "Ferme le Pop-Out",

    // Description Panel
    moderator: "Modérateur",
    direction: "Régie",
    company: "Entreprise",
    descriptionNotAvailable: "Aucune description disponible",

    // Landing Page
    browserNotSupportedText: "Votre navigateur n'est pas supporté. Veuillez utiliser un autre navigateur.",
    browserVersionNotSupportedText:
        "Cette version de votre navigateur n'est plus prise en charge. Veuillez mettre à jour votre navigateur ou utiliser un autre navigateur.",

    signInPanelSignIn: "Connexion",
    signInPanelName: "Nom",
    termsOfUse: "Conditions d'utilisation",
    acceptTermsOfUse: "J'accepte les conditions d'utilisation.",
    legalNotice: "Impressum",

    infoPanelArchiveText:
        "L'émission a été archivée. Pour consulter l'émission archivée, cliquez sur le bouton ci-dessous.",
    infoPanelArchiveTextLiveOnly: "Cette émission est terminée et l'enregistrement ne sera pas publié.",
    infoPanelArchiveNext: "Voir l'émission archivée",
    infoPanelWaitingText:
        "Cette émission n'est pas encore prête. Veuillez patienter jusqu'à ce qu'elle soit rendue" +
        " disponible par la régie.",
    infoPanelScheduleText1: "Cette émission sera disponible dans:",
    infoPanelScheduleText2: "Cette émission n'est plus disponible.",
    tokenInputPanelText:
        "Cette émission est sécurisée par un code d'accès. Veuillez entrer le code d'accès correspondant pour" +
        " visualiser l'émission.",
    tokenInputPanelToken: "Code d'accès",
    tokenInputPanelAuthenticate: "Envoyer",
    tokenInputPanelTokenError: "Le code d'accès envoyé n'est pas correct!",
    accessDeniedText: "Cette émission ne vous est pas accessible.",
    errorPanelDefaultText: "Oups! Une erreur s'est produite.",
    errorPanelInvalidIdText: "Cette émission n'existe pas.",
    errorPanelOnDemandProductionText: "Cette émission n'a pas encore été publié.",

    errorPanelLiveOnlyProduction: "Cette émission est terminée et l'enregistrement ne sera pas publié.",
};
