import * as types from "./actionTypes";
import { ClientState, SocketState } from "@adiacast/connector";

export const initialState = {
    serverTime: null,

    // time difference between client and server (server time - client time),
    // i.e. positive if client time is behind server time, and negative if client time is ahead of server time
    serverTimeDiff: 0,

    socketState: null,
    socketConnected: false,
    socketReconnecting: false,
    socketDisconnected: false,

    clientState: null,
    clientConnected: false,
    clientConnectedToProduction: false,

    clientInfo: null,
    productions: [],
    productionState: null,
    productionInfo: null,
    streamInfo: null,

    onAir: false,
    wasOnAir: false,

    socketError: null,
    serviceError: null,
};

export default function connector(state = initialState, action) {
    switch (action.type) {
        case types.CONNECTOR_SOCKET_ERROR: {
            return {
                ...state,
                socketError: action.error,
            };
        }

        case types.CONNECTOR_SERVICE_ERROR: {
            return {
                ...state,
                serviceError: action.error,
            };
        }

        case types.CONNECTOR_SERVER_TIME: {
            return {
                ...state,
                serverTime: action.serverTime,
                serverTimeDiff: action.serverTime - Date.now(),
            };
        }

        case types.CONNECTOR_SOCKET_STATE: {
            return {
                ...state,
                socketState: action.socketState,
                socketConnected: action.socketState === SocketState.CONNECTED,
                socketReconnecting: action.socketState === SocketState.RECONNECTING,
                socketDisconnected: action.socketState === SocketState.DISCONNECTED,
            };
        }

        case types.CONNECTOR_CLIENT_STATE: {
            const clientConnected = Boolean(action.clientState & ClientState.CONNECTED);
            const clientConnectedToProduction = Boolean(action.clientState & ClientState.CONNECTED_TO_PRODUCTION);
            return {
                ...state,
                clientState: action.clientState,
                clientConnected,
                clientConnectedToProduction,
            };
        }

        case types.CONNECTOR_CLIENT_INFO: {
            return {
                ...state,
                clientInfo: { ...action.clientInfo },
            };
        }

        case types.CONNECTOR_PRODUCTIONS: {
            return {
                ...state,
                productions: [...action.productions],
            };
        }

        case types.CONNECTOR_PRODUCTION_STATE: {
            return {
                ...state,
                productionState: { ...action.productionState },
            };
        }

        case types.CONNECTOR_PRODUCTION_INFO: {
            return {
                ...state,
                productionInfo: { ...action.productionInfo },
            };
        }

        case types.CONNECTOR_STREAM_INFO: {
            const isOnAir = action.streamInfo && action.streamInfo.isOnAir;
            const offAirTime = action.streamInfo && action.streamInfo.offAirTime;
            return {
                ...state,
                streamInfo: { ...action.streamInfo },
                onAir: isOnAir,
                wasOnAir: state.onAir ? !isOnAir : !!offAirTime,
            };
        }

        default:
            return state;
    }
}
