export const CONNECTOR_SOCKET_ERROR = "CONNECTOR_SOCKET_ERROR";
export const CONNECTOR_SERVICE_ERROR = "CONNECTOR_SERVICE_ERROR";
export const CONNECTOR_SERVER_TIME = "CONNECTOR_SERVER_TIME";
export const CONNECTOR_SOCKET_STATE = "CONNECTOR_SOCKET_STATE";
export const CONNECTOR_CLIENT_STATE = "CONNECTOR_CLIENT_STATE";
export const CONNECTOR_CLIENT_INFO = "CONNECTOR_CLIENT_INFO";
export const CONNECTOR_PRODUCTIONS = "CONNECTOR_PRODUCTIONS";
export const CONNECTOR_PRODUCTION_STATE = "CONNECTOR_PRODUCTION_STATE";
export const CONNECTOR_PRODUCTION_INFO = "CONNECTOR_PRODUCTION_INFO";
export const CONNECTOR_DIRECTION_INFO = "CONNECTOR_DIRECTION_INFO";
export const CONNECTOR_STREAM_INFO = "CONNECTOR_STREAM_INFO";
export const CONNECTOR_ACTIONS = "CONNECTOR_ACTIONS";
export const CONNECTOR_CLIENTS = "CONNECTOR_CLIENTS";

export const CONNECTOR_INIT = "CONNECTOR_INIT";
export const CONNECTOR_INIT_SUCCESS = "CONNECTOR_INIT_SUCCESS";
export const CONNECTOR_INIT_FAILURE = "CONNECTOR_INIT_FAILURE";
export const CONNECTOR_RECONNECT_SUCCESS = "CONNECTOR_RECONNECT_SUCCESS";
export const CONNECTOR_RECONNECT_FAILURE = "CONNECTOR_RECONNECT_FAILURE";

export const CONNECTOR_CONNECT_AS_DIRECTOR = "CONNECTOR_CONNECT_AS_DIRECTOR";
export const CONNECTOR_CONNECT_AS_MODERATOR = "CONNECTOR_CONNECT_AS_MODERATOR";
export const CONNECTOR_CONNECT_AS_USER = "CONNECTOR_CONNECT_AS_USER";
export const CONNECTOR_CONNECT_SUCCESS = "CONNECTOR_CONNECT_SUCCESS";
export const CONNECTOR_CONNECT_FAILURE = "CONNECTOR_CONNECT_FAILURE";

export const CONNECTOR_OPEN_PRODUCTION = "CONNECTOR_OPEN_PRODUCTION";
export const CONNECTOR_OPEN_PRODUCTION_SUCCESS = "CONNECTOR_OPEN_PRODUCTION_SUCCESS";
export const CONNECTOR_OPEN_PRODUCTION_FAILURE = "CONNECTOR_OPEN_PRODUCTION_FAILURE";

export const CONNECTOR_CONNECT_TO_PRODUCTION = "CONNECTOR_CONNECT_TO_PRODUCTION";
export const CONNECTOR_CONNECT_TO_PRODUCTION_SUCCESS = "CONNECTOR_CONNECT_TO_PRODUCTION_SUCCESS";
export const CONNECTOR_CONNECT_TO_PRODUCTION_FAILURE = "CONNECTOR_CONNECT_TO_PRODUCTION_FAILURE";

export const CONNECTOR_START_BROADCAST = "CONNECTOR_START_BROADCAST";
export const CONNECTOR_START_BROADCAST_SUCCESS = "CONNECTOR_START_BROADCAST_SUCCESS";
export const CONNECTOR_START_BROADCAST_FAILURE = "CONNECTOR_START_BROADCAST_FAILURE";

export const CONNECTOR_STOP_BROADCAST = "CONNECTOR_STOP_BROADCAST";
export const CONNECTOR_STOP_BROADCAST_SUCCESS = "CONNECTOR_STOP_BROADCAST_SUCCESS";
export const CONNECTOR_STOP_BROADCAST_FAILURE = "CONNECTOR_STOP_BROADCAST_FAILURE";

export const CONNECTOR_ADD_COMMAND = "CONNECTOR_ADD_COMMAND";
export const CONNECTOR_ADD_COMMAND_FAILURE = "CONNECTOR_ADD_COMMAND_FAILURE";

export const CONNECTOR_SET_CLIENT_STATE_ADD = "CONNECTOR_SET_CLIENT_STATE_ADD";
export const CONNECTOR_SET_CLIENT_STATE_REMOVE = "CONNECTOR_SET_CLIENT_STATE_REMOVE";
export const CONNECTOR_SET_CLIENT_STATE_SUCCESS = "CONNECTOR_SET_CLIENT_STATE_SUCCESS";
export const CONNECTOR_SET_CLIENT_STATE_FAILURE = "CONNECTOR_SET_CLIENT_STATE_FAILURE";

export const CONNECTOR_CLOSE_PRODUCTION = "CONNECTOR_CLOSE_PRODUCTION";
export const CONNECTOR_CLOSE_PRODUCTION_SUCCESS = "CONNECTOR_CLOSE_PRODUCTION_SUCCESS";
export const CONNECTOR_CLOSE_PRODUCTION_FAILURE = "CONNECTOR_CLOSE_PRODUCTION_FAILURE";

export const CONNECTOR_CLOSE = "CONNECTOR_CLOSE";
export const CONNECTOR_DESTROY = "CONNECTOR_DESTROY";
