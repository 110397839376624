import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { languages } from "./translations";

export const LanguageContext = React.createContext(languages.ENGLISH);

export function LanguageContextProvider({ children }) {
    const language = useSelector((state) => state.base.i18n.language);
    return <LanguageContext.Provider value={language || languages.ENGLISH}>{children}</LanguageContext.Provider>;
}

export const useLanguageContext = () => {
    const language = useContext(LanguageContext);
    return language || languages.ENGLISH;
};
