import { combineReducers } from "redux";

import global from "./features/base/global/reducer";
import i18n from "./features/base/i18n/redux/reducer";
import landingPage from "./features/landingPage/redux/reducer";
import livePlayer from "./features/liveCommunicator/redux/reducer";
import vodPlayer from "./features/vodCommunicator/redux/reducer";
import connector from "./features/connector/reducer";
import player from "./libs/@adiacast/player/src/view/redux/reducer";

const base = combineReducers({ global, i18n });

const rootReducer = combineReducers({
    base,
    landingPage,
    connector,
    player,
    livePlayer,
    vodPlayer,
});

export default rootReducer;
