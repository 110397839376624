let config_name = process.env.REACT_APP_CONFIG;
let config;

if (!config_name) {
    config_name = 'local';
}

try {
    config = require(`./Config_${config_name}.js`).Config;
} catch (err) {
    throw new Error("Can't find config file Config_" + config_name + '.js !');
}

config.name = config_name;
export const Config = config;
