import * as types from "./actionTypes";

export const connectorSocketError = (error) => ({ type: types.CONNECTOR_SOCKET_ERROR, error });
export const connectorServiceError = (error) => ({ type: types.CONNECTOR_SERVICE_ERROR, error });
export const connectorUpdateServerTime = (serverTime) => ({ type: types.CONNECTOR_SERVER_TIME, serverTime });
export const connectorSocketState = (socketState) => ({ type: types.CONNECTOR_SOCKET_STATE, socketState });
export const connectorClientState = (clientState) => ({ type: types.CONNECTOR_CLIENT_STATE, clientState });
export const connectorClientInfo = (clientInfo) => ({ type: types.CONNECTOR_CLIENT_INFO, clientInfo });
export const connectorProductions = (productions) => ({ type: types.CONNECTOR_PRODUCTIONS, productions });
export const connectorProductionState = (productionState, changes) => ({
    type: types.CONNECTOR_PRODUCTION_STATE,
    productionState,
    changes,
});
export const connectorProductionInfo = (productionInfo, changes) => ({
    type: types.CONNECTOR_PRODUCTION_INFO,
    productionInfo,
    changes,
});
export const connectorDirectionInfo = (directionInfo, changes) => ({
    type: types.CONNECTOR_DIRECTION_INFO,
    directionInfo,
    changes,
});
export const connectorStreamInfo = (streamInfo, changes) => ({
    type: types.CONNECTOR_STREAM_INFO,
    streamInfo,
    changes,
});
export const connectorActions = (actions) => ({ type: types.CONNECTOR_ACTIONS, actions });
export const connectorClients = (clients) => ({ type: types.CONNECTOR_CLIENTS, clients });

export const connectorInit = (serviceId, serviceUrl, notificationHandlers) => ({
    type: types.CONNECTOR_INIT,
    serviceId,
    serviceUrl,
    notificationHandlers,
});
export const connectorInitSuccess = () => ({ type: types.CONNECTOR_INIT_SUCCESS });
export const connectorInitFailure = () => ({ type: types.CONNECTOR_INIT_FAILURE });
export const connectorReconnectSuccess = () => ({ type: types.CONNECTOR_RECONNECT_SUCCESS });
export const connectorReconnectFailure = () => ({ type: types.CONNECTOR_RECONNECT_FAILURE });

export const connectorConnectAsDirector = (user, token) => ({ type: types.CONNECTOR_CONNECT_AS_DIRECTOR, user, token });
export const connectorConnectAsModerator = (user, token) => ({
    type: types.CONNECTOR_CONNECT_AS_MODERATOR,
    user,
    token,
});
export const connectorConnectAsUser = (clientId, info, modes, metadata) => ({
    type: types.CONNECTOR_CONNECT_AS_USER,
    clientId,
    info,
    modes,
    metadata,
});
export const connectorConnectSuccess = () => ({ type: types.CONNECTOR_CONNECT_SUCCESS });
export const connectorConnectFailure = (error) => ({ type: types.CONNECTOR_CONNECT_FAILURE, error });

export const connectorOpenProduction = (productionId) => ({ type: types.CONNECTOR_OPEN_PRODUCTION, productionId });
export const connectorOpenProductionSuccess = () => ({ type: types.CONNECTOR_OPEN_PRODUCTION_SUCCESS });
export const connectorOpenProductionFailure = (error) => ({ type: types.CONNECTOR_OPEN_PRODUCTION_FAILURE, error });

export const connectorConnectToProduction = (productionId, token) => ({
    type: types.CONNECTOR_CONNECT_TO_PRODUCTION,
    productionId,
    token,
});
export const connectorConnectToProductionSuccess = () => ({ type: types.CONNECTOR_CONNECT_TO_PRODUCTION_SUCCESS });
export const connectorConnectToProductionFailure = (error) => ({
    type: types.CONNECTOR_CONNECT_TO_PRODUCTION_FAILURE,
    error,
});

export const connectorStartBroadcast = () => ({ type: types.CONNECTOR_START_BROADCAST });
export const connectorStartBroadcastSuccess = () => ({ type: types.CONNECTOR_START_BROADCAST_SUCCESS });
export const connectorStartBroadcastFailure = (error) => ({ type: types.CONNECTOR_START_BROADCAST_FAILURE, error });

export const connectorStopBroadcast = () => ({ type: types.CONNECTOR_STOP_BROADCAST });
export const connectorStopBroadcastSuccess = () => ({ type: types.CONNECTOR_STOP_BROADCAST_SUCCESS });
export const connectorStopBroadcastFailure = (error) => ({ type: types.CONNECTOR_STOP_BROADCAST_FAILURE, error });

export const connectorSetClientStateAdd = (state) => ({
    type: types.CONNECTOR_SET_CLIENT_STATE_ADD,
    state,
});
export const connectorSetClientStateRemove = (state) => ({
    type: types.CONNECTOR_SET_CLIENT_STATE_REMOVE,
    state,
});
export const connectorSetClientStateSuccess = () => ({ type: types.CONNECTOR_SET_CLIENT_STATE_SUCCESS });
export const connectorSetClientStateFailure = (error) => ({ type: types.CONNECTOR_SET_CLIENT_STATE_FAILURE, error });

export const connectorCloseProduction = (productionId) => ({ type: types.CONNECTOR_CLOSE_PRODUCTION, productionId });
export const connectorCloseProductionSuccess = () => ({ type: types.CONNECTOR_CLOSE_PRODUCTION_SUCCESS });
export const connectorCloseProductionFailure = (error) => ({ type: types.CONNECTOR_CLOSE_PRODUCTION_FAILURE, error });

export const connectorClose = () => ({ type: types.CONNECTOR_CLOSE });
export const connectorDestroy = () => ({ type: types.CONNECTOR_DESTROY });
