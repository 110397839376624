import { all, cancel, delay, fork, put, race, select, take, takeEvery, takeLeading } from "redux-saga/effects";
import {
    changeAppState,
    getPublicProductionInfo,
    initializeLiveCommunicatorFailure,
    initializeLiveCommunicatorSuccess,
    leaveLiveCommunicator,
    newError,
    signIn,
    submitToken,
} from "../../landingPage/redux/actions";
import {
    CHANGE_APP_STATE,
    ENTER_PRODUCTION,
    INIT_LIVE_COMMUNICATOR,
    INIT_LIVE_COMMUNICATOR_FAILURE,
    INIT_LIVE_COMMUNICATOR_SUCCESS,
    LEAVE_LIVE_COMMUNICATOR,
    PUBLIC_PRODUCTION_INFO_FAILURE,
    PUBLIC_PRODUCTION_INFO_SUCCESS,
    SIGN_IN,
    SUBMIT_TOKEN,
} from "../../landingPage/redux/actionTypes";
import {
    connectorConnectAsUser,
    connectorConnectToProduction,
    connectorDestroy,
    connectorInit,
    connectorSetClientStateAdd,
    connectorSetClientStateRemove,
} from "../../connector/actions";
import {
    CONNECTOR_CLIENT_INFO,
    CONNECTOR_CLIENT_STATE,
    CONNECTOR_CONNECT_FAILURE,
    CONNECTOR_CONNECT_SUCCESS,
    CONNECTOR_CONNECT_TO_PRODUCTION_FAILURE,
    CONNECTOR_CONNECT_TO_PRODUCTION_SUCCESS,
    CONNECTOR_INIT_FAILURE,
    CONNECTOR_INIT_SUCCESS,
    CONNECTOR_PRODUCTION_INFO,
    CONNECTOR_PRODUCTION_STATE,
    CONNECTOR_PRODUCTIONS,
    CONNECTOR_RECONNECT_FAILURE,
    CONNECTOR_RECONNECT_SUCCESS,
    CONNECTOR_SERVICE_ERROR,
    CONNECTOR_SOCKET_ERROR,
    CONNECTOR_SOCKET_STATE,
    CONNECTOR_STREAM_INFO,
} from "../../connector/actionTypes";
import {
    playerDestroy,
    playerHide,
    playerPlay,
    playerReset,
    playerShow,
    playerStop,
} from "../../../libs/@adiacast/player/src/view/redux/actions";
import { PLAYER_INITIALIZED } from "../../../libs/@adiacast/player/src/view/redux/actionTypes";
import { hideStream, showStream } from "./actions";
import { HIDE_STREAM, SHOW_STREAM } from "./actionTypes";

import { Config } from "../../../config/Config";
import { uaParserResult } from "../../base/utils/isSupported";
import { log } from "../../base/utils/logger";
import { APP_STATE, COMMUNICATOR_MODES, ERRORS } from "../../../constants/constants";
import { ClientState, SocketState } from "@adiacast/connector";
import { validateSignInInputs } from "../utils/validateSignInInputs";
import { sanitizeUserInfo } from "../utils/userInfo";
import { getSavedClientInfo, setSavedClientInfo } from "../utils/clientInfo";
import isHttps from "../../base/utils/isHttps";

const getUrlParams = (state) => state.landingPage.urlParameters;
const getAppState = (state) => state.landingPage.appState;
const getService = (state) => state.landingPage.service;
const getProduction = (state) => state.landingPage.production;
const getAuthenticated = (state) => state.landingPage.authenticated;
const getCommunicatorMode = (state) => state.landingPage.communicatorMode;
const getDisplayStream = (state) => state.livePlayer.displayStream;
const getPlayerInitialized = (state) => state.player.initialized;
const getPlayerType = (state) => state.player.type;
const getServerTime = (state) => state.connector.serverTime;
const getServerTimeDiff = (state) => state.connector.serverTimeDiff;
const getProductionInfo = (state) => state.connector.productionInfo;
const getIsOnAir = (state) => state.connector.onAir;
const isProductionOpen = (state, productionId) => {
    const productions = state.connector.productions;
    return !!(Array.isArray(productions) && productions.includes(productionId));
};

function* initializeLiveCommunicatorWorker(service, production, communicatorMode) {
    try {
        // get token and userInfo from URL parameters
        let { auth: token, userInfo, popout } = yield select(getUrlParams);

        // initialize connector
        yield* initializeConnectorWorker(service._id, Config.serviceUrl);

        // check scheduling of production
        yield* checkSchedulingWorker(production, communicatorMode, yield select(getServerTime));

        // sign in (automatically or via sign in panel)
        userInfo = yield* signInWorker(production, userInfo);

        // connect user to service
        yield* connectToServiceWorker(service._id, production, userInfo, popout === "chat");

        // wait for production to be opened
        yield* awaitProductionWorker(production);

        // connect to production (includes verification of access token if necessary)
        yield* connectToProductionWorker(production, token);

        yield put(initializeLiveCommunicatorSuccess());
    } catch (e) {
        log.error(e);
        yield put(initializeLiveCommunicatorFailure(e));
    }
}

function* initializeConnectorWorker(serviceId, serviceUrl) {
    // initialize connector and open socket connection
    yield put(connectorInit(serviceId, serviceUrl));
    const { initFailure } = yield race({
        initSuccess: all({
            actionSuccess: take(CONNECTOR_INIT_SUCCESS),
            socketState: take(CONNECTOR_SOCKET_STATE),
        }),
        initFailure: race({
            actionFailure: take(CONNECTOR_INIT_FAILURE),
            socketError: take(CONNECTOR_SOCKET_ERROR),
            serviceError: take(CONNECTOR_SERVICE_ERROR),
        }),
    });
    if (initFailure) {
        throw new Error((initFailure.actionFailure || initFailure.serviceError || initFailure.socketError).error);
    }
}

function* signInWorker(production, userInfo) {
    const hideSignIn = !!production.communicatorCustomization.hideSignIn;
    const signInInputs = production.communicatorCustomization.signInInputs;
    userInfo = userInfo || {};

    if (hideSignIn) {
        userInfo = sanitizeUserInfo(signInInputs, userInfo);
        log.debug("userInfo (sign-in hidden)", userInfo);
        yield put(signIn(userInfo));
    } else {
        const [hasInvalidInputs] = validateSignInInputs(signInInputs, userInfo);
        if (hasInvalidInputs) {
            // show sign in panel (automatic sign-in was not possible)
            yield put(changeAppState(APP_STATE.SIGN_IN));
            ({ userInfo } = yield take(SIGN_IN));
            log.debug("userInfo (sign-in)", userInfo);
        } else {
            // automatically sign in with provided url parameters
            userInfo = sanitizeUserInfo(signInInputs, userInfo);
            log.debug("userInfo (auto sign-in)", userInfo);
            yield put(signIn(userInfo));
        }
    }

    return userInfo;
}

function* connectToServiceWorker(serviceId, production, userInfo = {}, chatOnly = false) {
    const clientKey = `${serviceId}|${production.uid}`;
    const savedClientInfo = getSavedClientInfo();

    while (true) {
        let clientId = null;
        if (savedClientInfo && savedClientInfo.hasOwnProperty(clientKey)) {
            const savedClient = savedClientInfo[clientKey];
            if (
                savedClient.clientId &&
                savedClient.info &&
                Object.entries(userInfo).every(([key, value]) =>
                    savedClient.info.hasOwnProperty(key) ? savedClient.info[key] === value : true
                )
            ) {
                clientId = savedClient.clientId;
                log.debug("use existing clientId", clientId);
            }
        }

        yield put(
            connectorConnectAsUser(clientId, { ...userInfo }, chatOnly ? { chatOnly: true } : null, {
                ...uaParserResult,
            })
        );
        const { connectSuccess } = yield race({
            connectSuccess: all({
                actionSuccess: take(CONNECTOR_CONNECT_SUCCESS),
                clientState: take(CONNECTOR_CLIENT_STATE),
                clientInfo: take(CONNECTOR_CLIENT_INFO),
                productions: take(CONNECTOR_PRODUCTIONS),
            }),
            connectFailure: race({
                actionFailure: take(CONNECTOR_CONNECT_FAILURE),
                socketError: take(CONNECTOR_SOCKET_ERROR),
                serviceError: take(CONNECTOR_SERVICE_ERROR),
            }),
        });
        if (connectSuccess) {
            const { clientInfo } = connectSuccess.clientInfo;
            log.debug("clientInfo", clientInfo);

            setSavedClientInfo({
                ...savedClientInfo,
                [clientKey]: {
                    clientId: clientInfo.clientId,
                    info: clientInfo.info,
                },
            });
            break;
        }

        yield put(changeAppState(APP_STATE.SIGN_IN));
        ({ userInfo } = yield take(SIGN_IN));
    }
}

function* awaitProductionWorker(production) {
    while (true) {
        if (yield select(isProductionOpen, production.uid)) {
            break;
        } else {
            yield all([put(changeAppState(APP_STATE.WAITING)), take(CONNECTOR_PRODUCTIONS)]);
        }
    }
}

function* checkSchedulingWorker(production, communicatorMode, serverTime) {
    if (communicatorMode === COMMUNICATOR_MODES.PREVIEW) return;

    let date, availableFrom, availableUntil, dateMin;
    if (!production.date) {
        throw new Error("production.date not available");
    }
    date = new Date(production.date).getTime();

    if (isNaN(date)) {
        throw new Error("could not convert production.date to timestamp");
    }

    if (production.hasOwnProperty("securitySettings")) {
        if (production.securitySettings.availableFrom) {
            availableFrom = new Date(production.securitySettings.availableFrom).getTime();
            if (availableFrom > date) {
                // disregard 'availableFrom' if it is after 'date' for some reason
                availableFrom = null;
            }
        }
        if (production.securitySettings.availableUntil) {
            availableUntil = new Date(production.securitySettings.availableUntil).getTime();
            const duration = production.duration * 60 * 1000;
            if (availableUntil < date + duration) {
                // disregard 'availableUntil' if it is before 'date' + 'duration' for some reason
                availableUntil = null;
            }
        }
    }

    // check if availableFrom is set; otherwise set the default (15 min)
    if (availableFrom) {
        dateMin = Math.min(availableFrom, date);
    } else {
        dateMin = date - 15 * 60 * 1000; // 15 minutes before start
    }

    if (serverTime < dateMin) {
        log.debug("production not available yet", serverTime, dateMin);

        const waitingTime = Math.max(dateMin - serverTime, 5000); // show at least a 5 second countdown for very
        // small waiting times
        yield put(changeAppState(APP_STATE.NOT_AVAILABLE, { waitingTime }));
        yield take(ENTER_PRODUCTION);
    } else if (availableUntil && serverTime > availableUntil) {
        log.debug("production is not available anymore", serverTime, availableUntil);

        yield put(changeAppState(APP_STATE.NOT_AVAILABLE, { waitingTime: -1 }));
        yield take(ENTER_PRODUCTION);
    }
}

function* connectToProductionWorker(production, token) {
    // get access token (if not set by url parameter)
    if (production.hasOwnProperty("securitySettings") && production.securitySettings.tokenEnabled) {
        if (token) {
            yield put(submitToken(token));
        } else {
            // show token input
            yield put(changeAppState(APP_STATE.AUTHENTICATE));
            ({ token } = yield take(SUBMIT_TOKEN));
        }
    }

    // connect to production
    while (true) {
        yield put(connectorConnectToProduction(production.uid, token));
        const { connectToProductionSuccess, connectToProductionFailure } = yield race({
            connectToProductionSuccess: all({
                actionSuccess: take(CONNECTOR_CONNECT_TO_PRODUCTION_SUCCESS),
                clientState: take(CONNECTOR_CLIENT_STATE),
                productionState: take(CONNECTOR_PRODUCTION_STATE),
                productionInfo: take(CONNECTOR_PRODUCTION_INFO),
                streamInfo: take(CONNECTOR_STREAM_INFO),
            }),
            connectToProductionFailure: race({
                actionFailure: take(CONNECTOR_CONNECT_TO_PRODUCTION_FAILURE),
                socketError: take(CONNECTOR_SOCKET_ERROR),
                serviceError: take(CONNECTOR_SERVICE_ERROR),
            }),
        });
        if (connectToProductionSuccess) {
            break;
        }

        // check if access was denied to production
        const error = (
            connectToProductionFailure.actionFailure ||
            connectToProductionFailure.serviceError ||
            connectToProductionFailure.socketError
        ).error;

        if (error && error.errorNo === 40399) {
            if (error.message === "token required") {
                yield put(changeAppState(APP_STATE.AUTHENTICATE));
                ({ token } = yield take(SUBMIT_TOKEN));
                continue;
            } else if (error.message === "invalid token") {
                yield put(changeAppState(APP_STATE.AUTHENTICATE));
                yield put(newError(ERRORS.INVALID_TOKEN_ERROR));
                ({ token } = yield take(SUBMIT_TOKEN));
                continue;
            }

            yield put(changeAppState(APP_STATE.ACCESS_DENIED));
            throw new Error(error);
        }

        throw new Error(error);
    }
}

function* socketStateWorker({ socketState }) {
    if (!(yield select(getAuthenticated))) {
        const { newSocketState } = yield race({
            authenticated: take(
                (action) => action.type === CHANGE_APP_STATE && action.appState === APP_STATE.AUTHENTICATED
            ),
            newSocketState: take(CONNECTOR_SOCKET_STATE),
        });
        if (newSocketState) return;
    }
    log.debug("socketStateWorker:", socketState);

    if (socketState === SocketState.RECONNECTING) {
        const { reconnectSuccess } = yield race({
            reconnectSuccess: all({
                actionSuccess: take(CONNECTOR_RECONNECT_SUCCESS),
                clientState: take(CONNECTOR_CLIENT_STATE),
            }),
            reconnectFailure: race({
                actionFailure: take(CONNECTOR_RECONNECT_FAILURE),
                initSuccess: take(CONNECTOR_INIT_SUCCESS),
                initFailure: take(CONNECTOR_INIT_FAILURE),
            }),
        });
        log.debug("socketStateWorker reconnect success:", !!reconnectSuccess);

        // client must be connected to production again for reconnect to be successful!
        if (
            reconnectSuccess &&
            reconnectSuccess.clientState &&
            Boolean(reconnectSuccess.clientState.clientState & ClientState.CONNECTED_TO_PRODUCTION)
        ) {
            // successfully reconnected
            // TODO
        } else {
            // failed to reconnect to old socket handler or not connected to production anymore
            // TODO
        }
    } else if (socketState === SocketState.DISCONNECTED || socketState === SocketState.NONE) {
        // TODO
    }
}

function* productionStateWorker({ productionState, changes }) {
    log.debug("productionStateWorker:", productionState, changes);

    if (productionState.state === "archived") {
        if (yield select(getDisplayStream)) {
            // wait until broadcast is over and stream will be hidden
            yield take(HIDE_STREAM);
        }
        yield put(changeAppState(APP_STATE.ARCHIVED));
        yield put(leaveLiveCommunicator());
        return;
    } else if (productionState.state === "deleted") {
        yield put(changeAppState(APP_STATE.INIT_FAILURE));
        yield put(newError());
        yield put(leaveLiveCommunicator());
        return;
    }

    if (!productionState.isOpen) {
        yield put(changeAppState(APP_STATE.WAITING));
        yield delay(10000);

        // wait for production to be opened again
        try {
            const { auth: token } = yield select(getUrlParams);
            const service = yield select(getService);
            let production = yield select(getProduction);

            yield* awaitProductionWorker(production);

            yield put(getPublicProductionInfo(service._id, production.uid));
            const action = yield take([PUBLIC_PRODUCTION_INFO_SUCCESS, PUBLIC_PRODUCTION_INFO_FAILURE]);
            if (action.type === PUBLIC_PRODUCTION_INFO_FAILURE) {
                yield put(changeAppState(APP_STATE.INIT_FAILURE));
                yield put(newError(action.error.message));
                yield put(leaveLiveCommunicator());
                return;
            }
            production = action.publicProductionInfo;

            yield* checkSchedulingWorker(production, yield select(getCommunicatorMode), yield select(getServerTime));
            yield* connectToProductionWorker(production, token);

            yield put(changeAppState(APP_STATE.AUTHENTICATED));
        } catch (e) {
            log.error(e);
            yield put(changeAppState(APP_STATE.INIT_FAILURE));
            yield put(newError());
            yield put(leaveLiveCommunicator());
        }
    }
}

function* streamInfoWorker({ streamInfo, changes }) {
    if (!(yield select(getAuthenticated))) {
        const { newStreamInfo } = yield race({
            authenticated: take(
                (action) => action.type === CHANGE_APP_STATE && action.appState === APP_STATE.AUTHENTICATED
            ),
            newStreamInfo: take(CONNECTOR_STREAM_INFO),
        });
        if (newStreamInfo) return;
    }
    log.debug("streamInfoWorker:", streamInfo, changes);

    const isOnAirChange = changes
        ? changes.hasOwnProperty("isOnAir")
        : streamInfo.hasOwnProperty("isOnAir") && streamInfo.isOnAir;
    if (isOnAirChange) {
        if (streamInfo.isOnAir) {
            if (yield select(getPlayerInitialized)) {
                const playerType = yield select(getPlayerType);
                if (
                    playerType &&
                    ((playerType === "ivs" && !streamInfo.isLowLatency) ||
                        (playerType !== "ivs" && streamInfo.isLowLatency))
                ) {
                    yield put(playerReset({ useLiveLowLatency: streamInfo.isLowLatency }));
                    yield take(PLAYER_INITIALIZED);
                }
            } else {
                yield take(PLAYER_INITIALIZED);
            }

            let source = streamInfo.playbackUrl;
            if (!source) {
                const service = yield select(getService);
                let productionInfo = yield select(getProductionInfo);
                if (!productionInfo) {
                    ({ productionInfo } = yield take(CONNECTOR_PRODUCTION_INFO));
                }

                source = `${productionInfo.streamingUrl}/${productionInfo.streamingApp}/video_${service._id}_${
                    productionInfo.uid
                }${streamInfo.isDynamicStream ? "_abr" : ""}/manifest.mpd`;
            }
            if (isHttps()) {
                source = source.replace(/^http:/, "https:");
            } else {
                source = source.replace(/^https:/, "http:");
            }
            log.debug("source", source);

            yield put(playerPlay(source));
        }

        // fallback for show/hide stream (in case id3 tags are missing)
        yield* displayStreamFallbackWorker(streamInfo);
    }
}

function* displayStreamFallbackWorker(streamInfo) {
    const streamIsShown = yield select(getDisplayStream);
    log.debug("displayStreamFallbackWorker:", streamInfo, streamIsShown);

    if (streamInfo.isOnAir) {
        // fallback for failed showStream
        const defaultMaxDelay = 60000;
        const defaultMinDelay = 2000;
        const onAirTime = streamInfo.onAirTime;
        const serverTimeDiff = yield select(getServerTimeDiff);
        const fallbackTimeout = Math.max(
            defaultMaxDelay - (Date.now() - (onAirTime - serverTimeDiff)),
            defaultMinDelay
        );
        log.debug(`show stream fallback timeout: ${fallbackTimeout}`);

        const { timeout } = yield race({
            showStream: take(SHOW_STREAM),
            timeout: delay(fallbackTimeout),
        });
        if (timeout) {
            const isOnAir = yield select(getIsOnAir);
            log.warn(`show stream fallback activated! (is on-air: ${isOnAir})`);

            if (isOnAir) {
                yield put(playerShow());
                yield put(showStream());
                yield put(connectorSetClientStateAdd(ClientState.VIDEO));
            }
            return;
        }
        log.debug("show stream fallback cancelled");
    } else {
        // fallback for failed hideStream
        const defaultDelay = 60000;
        const lowLatencyDelay = 30000;
        const fallbackTimeout = streamInfo.isLowLatency ? lowLatencyDelay : defaultDelay;
        log.debug(`hide stream fallback timeout: ${fallbackTimeout}`);

        const { timeout } = yield race({
            showStream: take(HIDE_STREAM),
            timeout: delay(fallbackTimeout),
        });
        if (timeout) {
            const isOnAir = yield select(getIsOnAir);
            log.warn(`hide stream fallback activated! (is on-air: ${isOnAir})`);

            if (!isOnAir) {
                yield put(hideStream());
                yield put(playerHide());
                yield put(playerStop());
                yield put(connectorSetClientStateRemove(ClientState.VIDEO));
            }
            return;
        }
        log.debug(`hide stream fallback cancelled`);
    }
}

function* serviceErrorWorker({ error }) {
    log.debug("serviceErrorWorker:", error);
    if (error.errorNo === 40399) {
        const isOnAir = yield select(getIsOnAir);
        if (isOnAir) return;

        yield put(changeAppState(APP_STATE.ACCESS_DENIED));
        yield put(leaveLiveCommunicator());
    }
}

function* leaveCommunicatorWorker() {
    yield put(connectorDestroy());
    yield put(playerDestroy());
}

function* initializeLiveCommunicator({ communicatorMode }) {
    const service = yield select(getService);
    const production = yield select(getProduction);

    let watchers;
    try {
        // setup watchers
        watchers = yield all([
            takeEvery(CONNECTOR_SOCKET_STATE, socketStateWorker),
            takeEvery(CONNECTOR_PRODUCTION_STATE, productionStateWorker),
            takeEvery(CONNECTOR_STREAM_INFO, streamInfoWorker),
            takeEvery(CONNECTOR_SERVICE_ERROR, serviceErrorWorker),
        ]);

        const task = yield fork(initializeLiveCommunicatorWorker, service, production, communicatorMode);
        const action = yield take([
            INIT_LIVE_COMMUNICATOR_SUCCESS,
            INIT_LIVE_COMMUNICATOR_FAILURE,
            LEAVE_LIVE_COMMUNICATOR,
        ]);
        if (action.type === INIT_LIVE_COMMUNICATOR_SUCCESS) {
            yield put(changeAppState(APP_STATE.AUTHENTICATED));
            yield take([LEAVE_LIVE_COMMUNICATOR]);
        } else if (action.type === INIT_LIVE_COMMUNICATOR_FAILURE) {
            const appState = yield select(getAppState);
            if (appState !== APP_STATE.ACCESS_DENIED) {
                yield put(changeAppState(APP_STATE.INIT_FAILURE, action.error));
            }
        } else {
            yield cancel(task);
        }

        yield fork(leaveCommunicatorWorker);
    } catch (e) {
        log.error(e);
        yield put(changeAppState(APP_STATE.INIT_FAILURE, e));
    } finally {
        if (watchers) yield cancel(watchers);
    }
}

function* watchAll() {
    yield all([takeLeading(INIT_LIVE_COMMUNICATOR, initializeLiveCommunicator)]);
}

export default watchAll;
