import * as types from "./actionTypes";
import { APP_STATE, COMMUNICATOR_MODES, PLAYER_MODES } from "../../../constants/constants";
import { transformProductionPayload } from "../utils/transformPayload";

export const initialState = {
    appState: APP_STATE.LOADING,
    urlParameters: {},

    loading: false,
    service: null,
    production: null,
    composition: null,

    isComposition: false,
    communicatorMode: null,
    playerMode: null,

    userInfo: null,
    token: null,
    waitingTime: null,
    authenticated: false,

    errorNo: null,
    errorMessage: null,
};

export default function landingPage(state = initialState, action) {
    switch (action.type) {
        case types.INIT_APP: {
            return {
                ...initialState,
                // don't overwrite service info when switching to archived production
                service: state.service,
            };
        }

        case types.URL_PARAMETERS_PARSED: {
            return {
                ...state,
                urlParameters: action.params,
            };
        }

        case types.CHANGE_APP_STATE: {
            const newState = {
                ...state,
                appState: action.appState,
                errorNo: undefined,
                errorMessage: undefined,
            };

            if (action.appState === APP_STATE.WAITING) {
                newState.authenticated = false;
            } else if (action.appState === APP_STATE.NOT_AVAILABLE) {
                newState.waitingTime = action.waitingTime;
            } else if (action.appState === APP_STATE.AUTHENTICATED) {
                newState.authenticated = true;
            }

            return newState;
        }

        case types.NEW_ERROR: {
            return {
                ...state,
                errorNo: action.errorNo,
                errorMessage: action.errorMessage,
            };
        }

        case types.PUBLIC_SERVICE_INFO_SUCCESS: {
            return {
                ...state,
                loading: false,
                service: action.publicServiceInfo || {},
            };
        }

        case types.PUBLIC_PRODUCTION_INFO_SUCCESS: {
            return {
                ...state,
                production: transformProductionPayload(action.publicProductionInfo || {}),
            };
        }

        case types.PUBLIC_COMPOSITION_INFO_SUCCESS: {
            return {
                ...state,
                composition: action.publicCompositionInfo || {},
            };
        }

        case types.CHECK_ACCESS_REQUEST:
        case types.CHECK_TOKEN_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.CHECK_ACCESS_SUCCESS:
        case types.CHECK_ACCESS_FAILURE:
        case types.CHECK_TOKEN_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case types.CHECK_TOKEN_SUCCESS: {
            return {
                ...state,
                token: action.token,
                loading: false,
            };
        }

        case types.SIGN_IN: {
            return {
                ...state,
                userInfo: action.userInfo,
            };
        }

        case types.SUBMIT_TOKEN: {
            return {
                ...state,
                token: action.token,
            };
        }

        case types.INIT_LIVE_COMMUNICATOR: {
            return {
                ...state,
                communicatorMode: action.communicatorMode || COMMUNICATOR_MODES.DEFAULT,
                isComposition: false,
                playerMode: PLAYER_MODES.LIVE,
            };
        }
        case types.INIT_VOD_COMMUNICATOR: {
            return {
                ...state,
                communicatorMode: COMMUNICATOR_MODES.DEFAULT,
                isComposition: action.isComposition || false,
                playerMode: PLAYER_MODES.VOD,
            };
        }

        default: {
            return state;
        }
    }
}
