import { all, cancel, fork, put, select, take, takeLeading } from "redux-saga/effects";

import { changeAppState, checkAccess, checkToken, newError } from "../../landingPage/redux/actions";
import {
    CHANGE_APP_STATE,
    CHECK_ACCESS_FAILURE,
    CHECK_ACCESS_SUCCESS,
    CHECK_TOKEN_FAILURE,
    CHECK_TOKEN_SUCCESS,
    INIT_VOD_COMMUNICATOR,
    LEAVE_VOD_COMMUNICATOR,
    SUBMIT_TOKEN,
} from "../../landingPage/redux/actionTypes";
import { playerDestroy, playerSetSource } from "@adiacast/player/src/view/redux/actions";
import { PLAYER_INITIALIZED } from "@adiacast/player/src/view/redux/actionTypes";

import { APP_STATE, COMMUNICATOR_MODES, ERRORS } from "../../../constants/constants";
import { log } from "../../base/utils/logger";
import isHttps from "../../base/utils/isHttps";

const getUrlParams = (state) => state.landingPage.urlParameters;
const getService = (state) => state.landingPage.service;
const getProduction = (state) => state.landingPage.production;
const getComposition = (state) => state.landingPage.composition;
const getAuthenticated = (state) => state.landingPage.authenticated;
const getIsComposition = (state) => state.landingPage.isComposition;

function* checkVodAccessWorker(service, production) {
    // check availability and ipRestrictions of vod
    let accessGranted = true;

    yield put(checkAccess(service._id, production.uid));
    const checkProductionAccessAction = yield take([CHECK_ACCESS_SUCCESS, CHECK_ACCESS_FAILURE]);
    if (checkProductionAccessAction.types === CHECK_ACCESS_FAILURE) {
        yield put(changeAppState(APP_STATE.INIT_FAILURE));
        yield put(newError(checkProductionAccessAction.error.message));
        return;
    }
    if (!checkProductionAccessAction.result) {
        yield put(changeAppState(APP_STATE.INIT_FAILURE));
        return;
    }

    if (checkProductionAccessAction.result.available === false) {
        //TODO: get server time to calculate waiting time
        accessGranted = false;
        yield put(changeAppState(APP_STATE.NOT_AVAILABLE, { waitingTime: -1 }));
    } else if (checkProductionAccessAction.result.ipValid === false) {
        accessGranted = false;
        yield put(changeAppState(APP_STATE.ACCESS_DENIED));
    }

    return accessGranted;
}

function* checkAuthenticationWorker(service, production, isComposition) {
    if (yield select(getAuthenticated)) return;

    if (production.hasOwnProperty("securitySettings") && production.securitySettings.tokenEnabled) {
        let { auth: token } = yield select(getUrlParams);
        while (true) {
            if (token) {
                // check validity of production/composition token
                yield put(checkToken(isComposition, service._id, production.uid, token));
                const checkTokenAction = yield take([CHECK_TOKEN_SUCCESS, CHECK_TOKEN_FAILURE]);
                if (checkTokenAction.types === CHECK_TOKEN_FAILURE) {
                    throw new Error(checkTokenAction.error.message);
                }

                if (checkTokenAction.valid) {
                    break;
                } else {
                    yield put(changeAppState(APP_STATE.AUTHENTICATE));
                    yield put(newError(ERRORS.INVALID_TOKEN_ERROR));
                    ({ token } = yield take(SUBMIT_TOKEN));
                }
            } else {
                yield put(changeAppState(APP_STATE.AUTHENTICATE));
                ({ token } = yield take(SUBMIT_TOKEN));
            }
        }
    }
}

function* startVodPlaybackWorker() {
    if (!(yield select(getAuthenticated))) {
        yield take((action) => action.type === CHANGE_APP_STATE && action.appState === APP_STATE.AUTHENTICATED);
    }

    const service = yield select(getService);
    const isComposition = yield select(getIsComposition);
    let production, composition;

    const streamingUrl = (service.streamingSettings && service.streamingSettings.streamingUrl) || "";
    const archiveFolder = (service.streamingSettings && service.streamingSettings.archiveFolder) || "";
    let recordingFile = "";
    if (isComposition) {
        composition = yield select(getComposition);
        recordingFile = composition.recording || recordingFile;
    } else {
        production = yield select(getProduction);
        recordingFile = production.recording || recordingFile;
    }

    let source = `${streamingUrl}/vod/_definst_/${archiveFolder}/${
        isComposition ? "compositions" : "recordings"
    }/${recordingFile}/manifest.mpd`;
    if (isHttps()) {
        source = source.replace(/^http:/, "https:");
    } else {
        source = source.replace(/^https:/, "http:");
    }

    log.debug("source", source);
    yield put(playerSetSource(source));
}

function* leaveCommunicatorWorker() {
    yield put(playerDestroy());
}

function* initializeVodCommunicator({ communicatorMode, isComposition }) {
    const service = yield select(getService);
    const production = isComposition ? yield select(getComposition) : yield select(getProduction);

    let watchers;
    try {
        // setup watchers
        watchers = yield all([takeLeading(PLAYER_INITIALIZED, startVodPlaybackWorker)]);

        if (communicatorMode !== COMMUNICATOR_MODES.PREVIEW && !isComposition) {
            const accessGranted = yield* checkVodAccessWorker(service, production);
            if (!accessGranted) return;
        }

        // check if production/composition is protected with a token
        yield* checkAuthenticationWorker(service, production, isComposition);

        yield put(changeAppState(APP_STATE.AUTHENTICATED));
        yield take(LEAVE_VOD_COMMUNICATOR);

        yield fork(leaveCommunicatorWorker);
    } catch (e) {
        log.error(e);
        yield put(changeAppState(APP_STATE.INIT_FAILURE, e));
    } finally {
        if (watchers) yield cancel(watchers);
    }
}

function* watchAll() {
    yield all([takeLeading(INIT_VOD_COMMUNICATOR, initializeVodCommunicator)]);
}

export default watchAll;
