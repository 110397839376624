import favicon from "../assets/favicons/favicon_netzebw.ico";

export const Config = {
    serviceUrl: "https://ac-app01.adia.tv",
    serviceId: "NETZE_BW",
    serviceIdFromUrl: false,

    customAssetsDomain: "d2xqlfo03xw9ub.cloudfront.net",
    keldanoDomain: "d3ig8klztrwejk.cloudfront.net",

    favicon,
    documentTitle: "davisio webcast",
    theme: "netzebw",

    languageOverrides: {
        en: {
            termsOfUse: "Privacy Protection",
        },
        de: {
            termsOfUse: "Datenschutz",
        },
        fr: {
            termsOfUse: "Protection des données",
        },
        it: {
            termsOfUse: "Tutela della privacy",
        },
    },
};
