import * as types from "./actionTypes";
import { languages } from "../translations";

export const initialState = {
    language: languages.ENGLISH,
};

export default function i18n(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_LANGUAGE: {
            return {
                ...state,
                language: action.language,
            };
        }
        default:
            return state;
    }
}
